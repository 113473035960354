.try-modules {
  border: 1px solid var(--washed-gray);
  border-radius: 4px;
  background-color: #fcfcfc;

  position: relative;
  display: grid;
  grid-template-columns: minmax(421px, 1fr) 1fr;

  &__info {
    padding: 20px 40px;
  }

  &__hero {
    padding: 34px 86px 22px 97px;
    height: 100%;
    position: relative;

    img {
      max-height: 100%;
      width: auto;
    }

    &-background {
      position: absolute;
      top: 0;
      left: 33px;
      height: 100%;
      width: 100%;
      background-color: #f6d891;
      transform: skewX(-12deg);
      z-index: -1;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
