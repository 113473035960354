.SplitButton {
  display: inline-flex;

  &__mainButton {
    &.ant-btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__menuButton {
    margin-left: 1px;

    &.ant-btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0 9px;
    }

    &.ant-btn-default,
    &.ant-btn-ghost {
      margin-left: 0;
      padding: 0 8px;

      &,
      &:hover,
      &:focus {
        border-left: 0;
      }
    }
  }

  &__menu {
    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }

    .ant-dropdown-menu-item-group-title {
      font-weight: 400;
      color: var(--lightest-gray);
    }
  }
}
