.app-details {
  height: 380px;
}

.app-details__user-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 4px;
}

.app-details__user-list-header {
  display: flex;
  border-bottom: 1px solid var(--faint-gray);
  padding: 8px 0;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--lightest-gray);
  top: 0;
  background-color: white;
  position: sticky;
}

.app-details__user-list-user {
  display: flex;
  padding: 16px 0;
  align-items: center;
  border-bottom: 1px solid var(--faint-gray);
  font-size: 13px;
}
