.retool-checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 20px;
  .switch-label {
    font-weight: 500;
    font-size: var(--fs-12);
    margin-left: 8px;
    color: var(--dark-gray);
  }
  .ant-switch-small {
    min-width: 24px;
    height: 14px;
  }
  .ant-switch-small:after {
    width: 10px;
    height: 10px;
    box-shadow: none;
  }

  .ant-switch-checked {
    background: var(--light-blue);
  }
}
