.main {
    display: flex;
    align-items: center;
    color: var(--lighter-gray);
    cursor: pointer;

    &:hover {
      color: var(--lightest-gray);
    }
  }

  .footerSection {
    background: var(--near-white);
    border-top: 1px solid var(--faint-gray);
    cursor: auto;
    margin-top: 4px;
    padding: 4px 16px;
  }

  .label {
    color: var(--light-gray);
    cursor: default;
    font-weight: 400;
  }

  /* Override retool-dropdown class specificity */
  .pillContainer {
    &,
    :global(.retool-dropdown) & {
      margin: 0;
      height: auto;
    }
  }