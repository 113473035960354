/*
  example usage:

  .my-class {
    composes: buttonReset from 'styles/v2/resets.module.css';

    padding: 4px 12px;
    ... other styles
  }
*/
.buttonReset {
  align-items: normal;
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: inherit;
  user-select: auto;
}

.inputReset {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  overflow: visible;
  background: transparent;
  border: none;
}
