@import 'base';

.edit-template-modal-body__middle-container {
  flex: 1 1 auto;
}

.edit-template-modal-body__footer-container {
  border-top: 1px solid var(--washed-gray);
}

.edit-template-modal-body__preview-container {
  margin-right: 52px;
  flex: 1 1 0;
}
.edit-template-modal-body__name-and-folder-container {
  flex: 1 1 0;
}

.edit-template-modal-body__section-header {
  letter-spacing: 0.03em;
}

.edit-template-modal-body__sidebar-container {
  flex: 0 0 auto;
  padding-right: 20px;
}

.edit-template-modal-body__preview-title-container {
  flex: 1 1 0;
}

.edit-template-modal-body__preview-screenshot-container {
  flex: 1 1 0;
  overflow: hidden;
}

.edit-template-modal-body__preview-nav-container {
  flex: 0 1 auto;
}
.edit-template-modal-body__preview-title-and-tags-container {
  flex: 1 0 auto;
}

.edit-template-modal-body__preview-title-description-container {
  flex: 2 1 auto;
}

.edit-template-modal-body__resource-container {
  min-height: 24px;
}
