.retool-button-toggle {
  background-color: white !important;

  &.retool-button-toggle__active {
    svg {
      color: var(--gray);
    }
    color: var(--gray);
  }
  &.retool-button-toggle__inactive {
    svg {
      color: var(--lighter-gray);
    }
    color: var(--lighter-gray);
    background-color: white !important;
  }
}
.retool-button-toggle:hover {
  background: var(--near-white);
  color: var(--gray) !important;
  svg {
    color: var(--gray);
  }
}
.retool-button-toggle:hover {
  background-color: var(--faint-gray) !important;
}
