.alert-slimline {
  padding-top: 6px;
  padding-bottom: 4px;
  padding-left: 28px;
  background: var(--light-yellow);
  border: 1px solid var(--dark-yellow);

  .ant-alert-icon {
    top: 9px;
    color: #a87b0c;
    left: 9px;
  }
  .ant-alert-message {
    font-weight: 500;
    color: var(--dark-gray);
  }
  .button-link {
    color: var(--near-black);
    font-weight: 600;
    text-decoration: underline;
  }
}

.resource-placeholder-modal {
  &.retool-modal-ds .ant-modal-content {
    padding: 0px;
  }
  .modal-header {
    padding-left: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--washed-gray);
    margin-bottom: 20px;
    padding-bottom: 16px;
    margin-top: 16px;
  }
  .resource-form-container {
    padding: 0px 20px;
    .resource-form {
      padding-bottom: 0px;
      .resource-detail-view {
        padding-bottom: 0px;
      }
    }
    .resource-form__end-buttons-container {
      padding: 12px 20px 16px;
      border-top: 1px solid var(--washed-gray);
    }
  }
}

.resource-confirmation-modal {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;

  .resource-confirmation-modal-emoji {
    font-size: xx-large;
    margin-bottom: 30px;
    margin-left: 10px;
    max-height: 32px;
  }

  .resource-confirmation-modal-header {
    color: var(--dark-gray);
    font-family: var(--sans-serif);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 7px;
  }

  .resource-confirmation-modal-text {
    color: var(--light-gray);
    font-family: var(--sans-serif);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 23px;
  }

  .confirmation-done-button {
    background-color: var(--blue);
    border-radius: 4px;
    color: white;
    font-size: 14px;
    width: 200px;
  }
}
