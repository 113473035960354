.main {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--fainter-gray);
    border-radius: 4px;
    width: 80px;
    height: 20px;
  }

  .lock {
    color: var(--lightest-gray);
    width: 16px;
    height: 16px;
  }

  .children {
    color: var(--lightest-gray);
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  }