.retool-copyable-text {
  position: relative;

  .retool-button {
    position: absolute;
    top: 4px; // (32px usual height of textinput - 24px height of copyabletext)/2
    right: 4px;
    height: 24px;
    padding: 0 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
}
