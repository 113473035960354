.browseAppDropdown {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
  }

  .browseFolderDropdown {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
  }

  .section {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.inRetoolPill {
      height: 28px;
      padding-left: 16px;
      &:hover {
        background-color: var(--near-white);
      }
    }
  }

  .folderIcon {
    width: 24px;
    padding-right: 10px;
    padding-top: 3px;
  }

  .sectionTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--lighter-gray);
    font-weight: 500;
  }

  .sectionText {
    color: var(--gray);

    &.inRetoolPill {
      color: var(--near-black);
      margin-right: 64px;
    }
  }