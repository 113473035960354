.retool-tooltip {
  // make markdown links more readable
  --accent-background: var(--lightest-blue);

  &.ant-tooltip {
    .ant-tooltip-inner {
      background-color: var(--near-black);
      font-weight: 500;
      line-height: 16px;
      color: var(--near-white);
      box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
      min-height: 0;
    }
    .ant-tooltip-arrow {
      display: none;
    }
  }

  code {
    background: #000;
    padding: 1px 4px;
    border-radius: 3px;
    font-size: 0.95em;
    font-weight: 600;
  }
}
