@import 'v2/button-reset';

.group-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 20px 24px;

  &__back.ant-btn-link {
    color: var(--light-gray);

    svg {
      transform: rotate(180deg);
      margin-right: 4px;
    }
  }

  &__search-bar {
    &,
    .retool-textinput {
      width: 100%;
    }

    display: flex;
    flex-direction: row;
    margin-top: 4px;
  }

  &__links {
    margin-top: 8px;
    flex-grow: 1;
    width: 100%;
  }

  &__group-link {
    @include button-reset();

    display: inline;
    width: 100%;
    height: 28px;
    border-radius: 4px;
    color: var(--dark-gray);
    padding: 0 8px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 18px;
    .retool-icon-locked {
      vertical-align: middle;
      margin-top: -2px;
    }
    &:hover {
      color: var(--blue);
      background: var(--faint-blue);
    }

    &--active {
      &,
      &:hover {
        color: var(--blue);
        background: var(--washed-blue);
      }
    }
  }
}
