.list-empty-state {
  width: 100%;
  padding: 40px;
  border: 2px dashed var(--faint-gray);
  color: var(--light-gray);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
