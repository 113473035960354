@import 'base';
@import '../../../styles/v2/reset.scss';

$header-height: 48px;

.global-shortcuts-container {
  height: 100%;
}
.two-column-container {
  display: grid;
  grid-gap: 12px;
  grid: auto / 1fr 1fr;
}
.editor {
  height: 100%;
  font-family: var(--sans-serif);
  font-size: var(--font-size-12);

  .panel-section-header {
    font-family: var(--sans-serif);
    font-weight: 600;
    color: $retool-dark-grey;
    border-top: 1px solid $retool-section-header-grey;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 30px;
    padding-top: 20px;
    font-size: 14px;
  }

  .panel-section-header--hide-divider {
    border: none;
    margin-top: 0px;
  }

  .header-navbar {
    border-bottom: 1px solid var(--washed-gray);
    padding: 0;
    height: $header-height;
    box-sizing: border-box;
    .navbar-group {
      height: 100%;
    }

    .navbar__logo {
      button {
        padding-right: 8px;
      }
    }

    .navbar__saving-indicator__dash {
      margin: 0 5px;
      height: 1px;
      background: $retool-medium-dark-grey;
      width: 10.5px;
    }

    .navbar__desktop-mobile {
      label {
        padding: 0 5px;
      }
    }

    .navbar__share-button {
      border: 1px solid $retool-grey;
      font-weight: normal;
      margin-left: 10px;
      border-radius: 4px;
    }

    .navbar__scale-buttons {
      margin: 0 28px;
      border-radius: 4px;
      display: flex;
      align-items: center;

      span {
        color: var(--gray);
      }

      button {
        border: none;
        height: 24px;
        width: 26px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--near-white);
      }

      button:disabled {
        background-color: var(--washed-gray);
        cursor: not-allowed;
        opacity: 0.3;
      }

      .navbar__scale-value {
        margin: 0 2px;
        width: 36px;
        text-align: center;
        font-weight: 600;
        color: var(--gray);
        font-size: 11px;
      }
    }

    .navbar__open-modal-buttons {
      display: flex;

      button {
        padding: 5px;
        width: 26px;
        height: 24px;
      }

      & > * + * {
        margin-left: 2px;
      }
    }

    .navbar__open-modal-button {
      position: relative;
      width: 47px;
      height: 30px;
      border-color: $retool-grey;
    }

    .navbar__open-modal-icon {
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      margin: auto;
      width: 27px;
      height: 20px;
      box-sizing: border-box;
      border: 1px solid $retool-grey;

      &::after {
        content: '';
        position: absolute;
        background: $retool-grey;
      }
    }

    .navbar__open-modal-icon-left::after {
      top: 1px;
      bottom: 1px;
      left: 1px;
      width: 4px;
      border-radius: 3px;
    }

    .navbar__open-modal-icon-bottom::after {
      bottom: 1px;
      left: 1px;
      right: 1px;
      height: 4px;
      border-radius: 3px;
    }

    .navbar__open-modal-icon-right::after {
      top: 1px;
      bottom: 1px;
      right: 1px;
      width: 4px;
      border-radius: 3px;
    }

    .navbar__open-modal-button-selected .navbar__open-modal-icon {
      border-color: $retool-azure;

      &::after {
        background: $retool-azure;
      }
    }

    .navbar__run-button {
      position: relative; // this is to make the red OnboardingPulse circle have a relative parent to anchor it's position absolute to
      display: flex;
    }
  }

  .editor-center {
    width: 100%;
  }
  .editor-body {
    background: #f4f4f4;
    display: flex;
    flex-direction: row;
    // 56px cannot be set as a variable because variables are not supported
    // inside calc.
    height: calc(100% - #{$header-height});

    .SplitPane.horizontal {
      // position: relative !important; <-- without uncommenting this, resizing widget pane doesn't work

      .Pane.horizontal {
        overflow: auto;
      }

      .query-editor-body {
        .Pane.horizontal {
          overflow: hidden;
        }
      }
    }

    .view-selector-wrapper {
      padding-top: 10px;

      .retool-view-selector-active svg {
        color: var(--gray);
      }

      .retool-view-selector-inactive svg {
        color: var(--lighter-gray);
      }

      .retool-view-selector-active {
        border-color: initial;
        box-shadow: none;
        color: #d9d9d9;
      }
    }

    .editor-canvas-container {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;
    }

    .editor-property-manager {
      width: 271px;

      label {
        user-select: none;
      }

      .retool-code-editor__popout-editor {
        .CodeMirror .CodeMirror-scroll {
          max-height: calc(16px + (12 * 16px)); // max height for 12 lines for code editors in the RHS
        }
      }
      .editor-property-manager__property-browser-container {
        position: absolute;
        top: 564px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        box-shadow: $retool-editor-box-shadow 26px -2px 23px 5px;

        .editor-property-manager__property-browser-container__resize {
          position: absolute;
          top: -3px;
          left: 0;
          right: 0;
          z-index: 2;
          height: 6px;
          cursor: ns-resize;
        }

        .property-browser {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 0;
          border: none;
          box-shadow: $retool-editor-box-shadow 26px -2px 23px 5px;

          & > h4 {
            margin: 0;
            border-top: 1px solid $retool-editor-border-grey;
            border-bottom: 1px solid $retool-editor-border-grey;
            padding: 20px 15px;
            cursor: ns-resize;
          }

          .property-browser__json-tree-container {
            position: absolute;
            top: 57px;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: scroll;
          }
        }
      }
    }

    .editor-widget-picker {
      width: 271px;
      background-color: white;
    }

    .editor-rhs__tabs {
      border-bottom: 1px solid var(--washed-gray);
      z-index: 4;
      width: 100%;
      background: var(--near-white);
      #editor-rhs-tab-Inspect {
        margin-right: 20px;
      }
      #editor-rhs-tab-Insert {
        margin-right: 0px;
      }
      .retool-tab-menu,
      .retool-tab-menu-tab {
        height: 36px;
        line-height: 36px;
        display: flex;
        align-items: center;
      }
      .retool-tab-menu {
        margin-bottom: -1px; // overlap selected tab with border
        z-index: 3;
        display: flex;
        justify-content: center;
      }
    }

    .editor-property-manager,
    .editor-widget-picker {
      background: white;
      flex-shrink: 0;
      height: 100%;
      transition: 50ms;
      display: flex;
      flex-direction: column;

      .code-input input,
      textarea.code-input {
        font-family: var(--monospace);
        font-size: var(--font-size-12);
      }

      .select-widget--options-input {
        margin-top: 5px;
      }

      pre {
        white-space: pre-wrap;
      }
    }
  }

  .query-editor-closed-bar {
    padding: 1px 20px 1px 20px;
    font-family: var(--monospace);
    font-weight: bold;
    min-height: 35px;
    background-color: $grey-1;
    height: 35px;
    line-height: 35px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.panel-header-dropdown-module {
  background-color: var(--fainter-purple);
}

.widget-editable-text {
  border: 1px dashed $grey-7;
  padding: 2px;
  border-radius: 3px;
  font-size: 15px;
}

.widget-delete-button {
  margin: 0px 20px 10px 0px;
}

.widget-save-button {
  margin: 0px 20px 10px 0px;
}

.editor-form-bottom-container {
  .ant-checkbox-wrapper {
    margin-bottom: 5px;
  }
  .update-button {
    width: 240px;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.active-widget-editable-text {
  margin-right: 10px;
}

.separator {
  height: 1px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: $grey-5;
}

.editor-form-buttons {
  width: 65px;
  flex-shrink: 0;
}

.editor-form-header {
  border-bottom: 1px solid var(--washed-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  font-weight: 600;
  padding: 0 16px;
  background: #fff;
  z-index: 1000;

  &__name {
    flex: 1 0 0;
    min-width: 0;

    &:not(:first-child) {
      margin-left: 6px;
    }
  }

  .retool-icon:hover {
    cursor: pointer;
    color: var(--light-blue);
    transition: 250ms;
  }

  .retool-icon-trash:hover {
    color: var(--red);
  }

  .retool-inline-edit-wrapper {
    margin-left: -8px;
    margin-right: 10px;
    padding-left: 0;
    border: none;
    font-size: var(--font-size-12);
    cursor: pointer;
    font-weight: 600;
    width: 100%;
  }
}
.editor-form-body {
  flex: 1;
  overflow: auto;
  padding-bottom: 70px; // to make room for intercom
  .retool-collapse {
    background-color: white;
  }
}
.editor-groups {
  .retool-collapse-panel {
    &:not(:first-child) {
      border-top: 1px solid var(--washed-gray);
    }
  }

  .widget-editor-toggle {
    margin-top: 15px;
    display: flex;
    align-items: center;

    .widget-editor-toggle__label {
      display: block;
      margin-left: 15px;
      line-height: var(--line-height-16);
      font-size: var(--fs-12);
      font-weight: 500;
      border-bottom: dashed 1px #aeafbb;
    }
  }
  .editor-group {
    border-bottom: 1px solid $retool-grey;

    .editor-group__title {
      font-size: var(--font-size-12);
      font-weight: 600;
    }
    .twitter-picker {
      margin-left: -5px;
    }

    & > label {
      position: relative;
      z-index: 3;
    }
    .editor-switch {
      margin: 5px 0px 0px 0px;
      font-size: 12px;
    }
    .editor-switch--pushed-right {
      margin-left: 15px;
    }

    .cm-evaluation-wrapper {
      border-radius: 4px;
    }
    .editor-group__editors {
      .editor-group__editor {
        margin-bottom: 12px;
        color: var(--dark-gray);
      }
    }
    .ant-collapse-content-box {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 16px;
      .widget-editor-checkbox {
        margin-top: 8px;
      }
      .widget-editor-checkbox ~ .widget-editor-checkbox {
        margin-top: 0px;
      }
      .widget-input + .widget-editor-checkbox {
        margin-top: 8px;
      }
      .widget-input {
        position: relative; // this is to make the red OnboardingPulse circle have a relative parent to anchor it's position absolute to
      }

      > div:first-child {
        margin-top: 0;
        .label-title,
        .label-title-container {
          margin-top: 0;
        }
      }
    }
  }
}

.editor__share-menu-modal {
  padding: 0;
  .ant-modal-content {
    padding: 16px 20px;
  }
}
