@import '../Checkbox/Checkbox.scss';

.retool-color-picker {
  border-radius: 4px;
  $color-box-border: 1px solid rgba(0, 0, 0, 0.15);
  border: $color-box-border;

  &:hover {
    border-color: var(--lightest-blue);
  }

  .color-view {
    cursor: pointer;
    transition: 250ms;
    padding: 5px 8px;
    border-radius: 4px;

    .color-box {
      width: 20px;
      height: 20px;
      border: $color-box-border;
    }
  }
  .custom-color-picker .color-box {
    border: $color-box-border;
    flex-shrink: 0;
  }
  .custom-color-picker .retool-textinput {
    flex: 1 0 auto;
  }
  .color-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 28px);
    grid-template-rows: repeat(auto-fit, 28px);
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    justify-items: center;
  }

  .custom-color-picker {
    margin-top: 12px;
  }

  .color-box {
    width: 28px;
    height: 28px;
    border-radius: 4px;

    &.gray-checkmark .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: rgba(0, 0, 0, 0.3);
    }

    .ant-checkbox,
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: inherit !important;
      background: inherit !important;
      top: 0;
    }

    .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
      box-shadow: none;
      border: $color-box-border;
      box-shadow: 0 0 0 4px var(--faint-blue);
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-inner {
      border: $color-box-border !important;
    }
    .ant-checkbox-checked:after {
      border: none;
    }
    .ant-checkbox,
    .ant-checkbox-checked,
    .ant-checkbox-wrapper,
    .ant-checkbox-inner {
      width: 100%;
      height: 100%;
      border-radius: 4px !important;
      box-shadow: none;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border: inherit;
      box-shadow: 0 0 0 4px var(--faint-blue);
    }

    .ant-checkbox-inner::after {
      margin-left: 3px;
    }
  }
  .retool-textinput input {
    height: 28px;
  }
}

.retool-color-picker-popover {
  border: none;
  padding: 12px;
}
