.retool-button {
  &.ant-btn {
    font-family: var(--sans-serif);
    height: 2rem;
    border-radius: 4px;
    border: none;
    font-size: var(--font-size-12);
    line-height: var(--line-height-20);
    color: white;
    font-weight: 600;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    padding: 0 16px;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    text-shadow: none;
    text-align: center;
    justify-content: center;
    min-width: 0;

    &.testing-button {
      height: 24px;
    }
  }
  svg {
    flex-shrink: 0;
  }
  .atn-btn:hover,
  .atn-btn:focus {
    transition: all 0.15s ease-in-out;
  }

  &.ant-btn-primary {
    background: var(--blue);
  }
  &.ant-btn-primary:hover,
  &.ant-btn-primary:focus {
    background: var(--light-blue);
  }
  &.ant-btn-secondary {
    background: var(--faint-blue);
    color: var(--blue);

    &:hover,
    &:focus {
      background: var(--washed-blue);
    }
  }
  &.ant-btn-danger {
    background: var(--red);
  }
  &.ant-btn-danger:hover,
  &.ant-btn-danger:focus {
    background: var(--light-red);
  }
  &.ant-btn-default {
    border: 1px solid var(--light-blue);
    color: var(--blue);
  }
  &.ant-btn-ghost {
    border: 1px solid var(--lighter-gray);
    color: var(--light-gray);
  }
  &.ant-btn-ghost:hover,
  &.ant-btn-ghost:focus,
  &.ant-btn-default:hover,
  &.ant-btn-default:focus {
    border: 1px solid var(--lightest-blue);
    color: var(--light-blue);
    background: var(--faint-blue);
  }

  &.ant-btn-link {
    color: var(--blue);
    font-weight: 500;
    padding: 0;
  }
  &.ant-btn-link:hover {
    color: var(--light-blue);
  }
  &.ant-btn-link-danger {
    padding: 0;
    color: var(--red);
  }
  &.ant-btn-link-danger:hover {
    color: var(--dark-red);
  }
  &.ant-btn-link-gray {
    color: var(--gray);
    background: var(--near-white);
    padding: 0;
  }
  &.ant-btn-link-gray.transparent-button {
    background: transparent;
    font-weight: 500;
  }
  &.ant-btn-link-gray:hover {
    color: var(--blue);
    background: var(--faint-blue);
  }
  &:disabled,
  [disabled] {
    &,
    &:hover {
      background: var(--lighter-gray);
      color: var(--white);
      cursor: not-allowed;
    }
  }
  &.ant-btn-primary:disabled:hover,
  &.ant-btn-primary[disabled]:hover {
    background: var(--lighter-gray);
    color: white;
  }
  &.ant-btn-link-gray:disabled {
    background: var(--faint-gray);
    opacity: 0.4;
  }

  &.ant-btn-link:disabled,
  &.ant-btn-link[disabled] {
    background-color: transparent;
    color: var(--lighter-gray);
  }
  span {
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
