@import 'base';
@import '../../../styles/v2/reset.scss';

.headerized-menu-item {
  background: var(--near-white);
  padding: 6px 16px;
  line-height: 16px;
  border-bottom: 1px solid var(--washed-gray);
  margin-bottom: 4px;
}

.retool-dropdown-menu,
.ant-dropdown-menu {
  padding: 4px 0;
  overflow: hidden;
  border: 1px solid var(--washed-gray);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  .no-top-margin {
    margin-top: 0;
  }
  hr {
    margin: 4px 0;
  }
  li.retool-dropdown-menu-item {
    cursor: pointer;
    height: 28px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 4px 16px;
  }
  .retool-dropdown-menu-item {
    margin-bottom: 0px !important;
  }

  li.retool-dropdown-menu-item:hover {
    background: var(--near-white);
    color: var(--blue);
  }

  &.clear-padding {
    padding: 0px;
  }
}

.upload-container {
  display: block;
  margin: -4px -16px;

  div.ant-upload {
    display: block;

    span.ant-upload {
      height: 28px;
      display: block;
      padding: 4px 16px;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
//   }
// }
