.shortcut-details-modal {
  position: absolute;
  top: auto;
  bottom: 52px;
  right: 12px;

  .ant-modal-content {
    color: var(--near-white) !important;
    background: var(--near-black);
    padding: 0px !important;
    border: 1px solid #000 !important;
    border-radius: 4px;
  }

  .ant-modal-body {
    overflow-y: auto;
    max-height: 76vh;
  }

  .ant-modal-title {
    color: var(--near-white) !important;
    font-size: var(--font-size-12) !important;
    background: var(--near-black);
    border-bottom: 1px solid var(--dark-gray);
    padding: 4px 12px;
    margin: 0px !important;
  }
  .ant-modal-header {
    margin: 0px;
  }
  .ant-modal-close-x {
    color: var(--near-white);
    margin-top: -6px !important;
    margin-right: -8px !important;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
