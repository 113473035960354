@import 'base';

.resource-form {
  display: flex;
  padding-bottom: 120px;
  .light-box {
    width: 200px;
    margin-left: 40px;
    background-color: var(--near-white);
    border-radius: var(--spacing-4);
    padding: var(--spacing-24);
    height: 100%;
    margin-bottom: 20px;
    code {
      font-weight: bold;
    }
  }

  .query-scroller {
    flex: 1 1 0;
  }
  .custom-auth-step {
    border: 1px solid var(--faint-gray);
    border-radius: 4px;
    .custom-auth-step-header {
      padding: 4px 0;
      background-color: var(--near-white);
      border-radius: var(--spacing-4);
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--spacing-12);
      .retool-select {
        width: 144px;
        padding-left: var(--spacing-8);
      }
      .custom-auth-step-delete {
        color: var(--red);
        padding-right: var(--spacing-16);
      }
    }
    .custom-auth-step-form {
      min-height: 40px;
      padding: 0 12px 12px 12px;
      .ant-select {
        height: var(--spacing-32);
      }
      .query-scroller {
        width: 100%;
      }
      .exported-variables {
        background-color: var(--near-white);
        border-radius: var(--spacing-4);
        padding: 8px 8px;
        font-size: 11px;
      }
    }
  }
}

.resource-detail-view {
  align-self: flex-start;
  width: 100%;
  padding-bottom: 60px;

  .ant-spin.ant-spin-spinning {
    max-height: 100%;
  }

  .resource-form__common-fields {
    margin-bottom: 40px;
  }

  .resource-form__end-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    button[type='submit'] {
      font-size: 16px;
      line-height: 16px;
      width: 120px;
      color: $white;
    }
    .resource_form__end-buttons-container {
      display: flex;
      justify-content: flex-end;
    }
    .resource-form__done-button {
      font-size: 12px;
      background-color: $retool-azure;
      &[disabled] {
        background-color: 'var(--light-blue)';
        &::hover {
          color: inherit;
        }
      }
    }
    .resource-form__delete-button {
      background-color: $retool-red;
      margin-right: 20px;
    }
  }

  .resource-form__add-button {
    color: $blue-5;
    margin-right: 20px;
    cursor: pointer;
    margin-top: 40px;
    font-size: 16px;
  }

  .resource-input {
    margin: 15px;
    input {
      font-family: var(--monospace);
      height: 35px;
    }
  }

  .large-button {
    margin-bottom: 10px;
    width: 160px;
  }

  .error-message {
    margin-top: 24px;
    color: $red-4;
    font-size: 16px;
    margin-bottom: 24px;
  }
}

.instructions {
  margin-top: 20px;
  font-size: 1.2em;
}

.autogenerated-app-form__end-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  button {
    font-size: 16px;
    line-height: 16px;
    width: 270px;
    color: $white;
  }
  .resource-form__done-button {
    background-color: $retool-azure;
    &[disabled] {
      background-color: $retool-grey;
      &::hover {
        color: inherit;
      }
    }
  }
}
