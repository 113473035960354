.retool-checkbox.ant-checkbox-wrapper {
  font-weight: 500;
  font-size: var(--fs-12);
  height: 24px;
  display: flex;
  align-items: center;
  color: var(--dark-gray);
  span {
    display: flex;
    align-items: center;
  }
  .ant-checkbox {
    top: 0px;
  }
  .ant-checkbox-inner {
    border-radius: 4px;
    border-color: var(--lighter-gray);
    box-shadow: 0 0 0 0.5px var(--lighter-gray);
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--light-blue);
    box-shadow: 0 0 0 1px var(--light-blue), 0 0 0 3px var(--washed-blue);
  }

  .ant-checkbox-wrapper-disabled:hover .ant-checkbox-inner,
  .ant-checkbox-disabled:hover .ant-checkbox-inner {
    border-color: var(--lighter-gray);
    box-shadow: 0 0 0 0.5px var(--lighter-gray);
  }
  + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--light-blue);
    border-color: var(--light-blue);
    box-shadow: 0 0 0 0.5px var(--light-blue);
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background: var(--washed-gray);
    border-color: var(--lighter-gray);
    box-shadow: 0 0 0 1px var(--lighter-gray);
    &::after {
      border-color: white;
    }
  }
}
