.retool-tabs {
  .ant-tabs-bar {
    border-bottom: 1px solid var(--washed-gray);
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background-color: var(--dark-gray);
  }

  .ant-tabs-nav-container,
  .ant-tabs-nav-wrap,
  .ant-tabs-nav,
  .ant-tabs-tab {
    height: 40px;
    line-height: 22px;
  }

  .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav,
  .ant-tabs-tab-active {
    color: var(--light-gray);
    font-weight: 500;
  }
  .ant-tabs-tab-active {
    color: var(--dark-gray);
  }

  .ant-tabs-tab:hover {
    color: var(--dark-gray);
  }

  .ant-tabs-content {
    font-family: var(--sans-serif);
    font-size: var(--font-size-12);
    height: 100%;
  }
}
