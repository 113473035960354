.retool-select {
  box-sizing: border-box;
  border-radius: 4px;
  font-size: var(--font-size-12);
  font-weight: 500;
  min-height: 32px;
  .ant-select-selection__placeholder {
    display: inherit !important;
    color: var(--light-gray);
    font-weight: 400;
  }
  .ant-select-selection {
    min-height: 30px;
    height: 100%;
  }
  .ant-select-selection__clear {
    right: 20px;
    top: 50%;
  }
  .ant-select-selection__rendered {
    line-height: 30px;
  }
  .ant-select-selection__choice {
    height: 24px !important;
    line-height: var(--line-height-24) !important;
    border-radius: 3px;
  }
  .ant-select-selection-selected-value {
    font-family: var(--sans-serif) !important;
  }
  // Arrow
  .ant-select-arrow {
    border: none;
    top: 0%;
    right: 2px;
    margin-top: 0;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: -1px;
  }
  .ant-select-dropdown-menu-item {
    line-height: var(--line-height-20);
    padding: 2px 8px;
  }

  // Focus
  &:not(.disabled).ant-select-selection:hover {
    border: 1px solid var(--lightest-blue);
  }

  .ant-select-selection:focus {
    border: 1px solid var(--lightest-blue);
    box-shadow: 0 0 0 3px var(--washed-blue);
  }

  // Error
  &.is-error > .ant-select-selection {
    border: 1px solid var(--lightest-red) !important;
  }

  &.is-error > .ant-select-selection:focus {
    box-shadow: 0 0 0 3px var(--washed-red) !important;
  }
}
