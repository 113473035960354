/* a retool version of
   https://raw.githubusercontent.com/FarhadG/code-mirror-themes/master/themes/summerfruit.css */

.cm-s-summerfruit {
  font-size: 1em;
  font-family: var(--monospace);
  letter-spacing: 0.3px;
  background: #fdfdfd;
  color: #181818;
}
.cm-s-summerfruit .CodeMirror-gutters {
  z-index: 3;
  border: none;
  background: #f6f9fc;
}
.cm-s-summerfruit .CodeMirror-linenumber {
  color: #8898aa;
}
.cm-s-summerfruit div.CodeMirror-cursor {
  border-left: 1px solid #181818;
}
.cm-s-summerfruit .CodeMirror-activeline-background {
  background: #ffffff0d;
}
.cm-s-summerfruit .CodeMirror-selected {
  background: #b7dce8;
}
.cm-s-summerfruit .cm-comment {
  color: #91a2b0;
}
.cm-s-summerfruit .cm-keyword {
  color: #5c65c1;
}
.cm-s-summerfruit .cm-string {
  color: #159570;
}
.cm-s-summerfruit .cm-variable-2 {
  color: #f92672;
}
.cm-s-summerfruit .cm-property {
  color: null;
}
.cm-s-summerfruit .cm-atom {
  color: null;
}
.cm-s-summerfruit .cm-number {
  color: #fb6620;
}
.cm-s-summerfruit .cm-operator {
  color: #fb6620;
}

.cm-s-summerfruit .cm-tag:not(.cm-bracket) {
  color: #da1225;
}

.cm-s-summerfruit .cm-attribute {
  font-style: italic;
  color: #e36209;
}
