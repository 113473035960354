.retool-pagination {
  display: flex;
  justify-content: center;

  li {
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    font-family: var(--sans-serif);
    a {
      color: var(--light-gray);
    }
  }

  .ant-pagination-item {
    border: 1px solid var(--washed-gray);
  }
  .ant-pagination-item:hover {
    // border: 1px solid transparent;
    border: 1px solid var(--near-white);
    background-color: var(--near-white);
    a {
      color: var(--dark-gray);
    }
  }

  .ant-pagination-item-active {
    border: none;
    background-color: var(--near-white);
    a {
      color: var(--gray);
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    a {
      border: none;
    }
  }

  .ant-pagination-item-ellipsis {
    width: 28px;
  }

  .ant-pagination-item-link-icon {
    color: var(--light-gray) !important;
  }
}
