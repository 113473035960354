.button {
    background: var(--blue);
    border: none;
    color: #fff;
  }
  .button:disabled {
    background: var(--lighter-gray);

    /* NOTE: disabled inputs don't emit mouse events in some cases, breaking the tooltip dismiss
     interaction. So for disabled buttons we need a wrapper element to support tooltips. */
    pointer-events: none;
  }

  .buttonWrapper {
    cursor: not-allowed;
    display: inline-block;
  }