.title {
    font-weight: 500;
  }

  .expandButton {
    margin-bottom: -8px;
  }

  .expandButton.expandButton {
    display: flex;
    color: var(--light-gray);
  }

  .truncated {
    line-clamp: 4;
    /* stylelint-disable-next-line */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* stylelint-disable-next-line */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }