.retool-help-button {
  position: absolute;
  z-index: var(--help-bubble-z-index);
  bottom: 24px;
  right: 24px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin: 0;
  background: var(--near-black);
  cursor: pointer;
  box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.19);
  animation: intercom-lightweight-app-launcher 250ms ease;
  display: grid;
  place-content: center;
  transition: background-color 200ms ease;
  &:hover {
    background-color: var(--blue);
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.24);
  }
}

.retool-help-dropdown {
  box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.05), 0px 6.26664px 18.7999px rgba(0, 0, 0, 0.0359427),
    0px 3.35045px 10.0513px rgba(0, 0, 0, 0.0298054), 0px 1.87823px 5.6347px rgba(0, 0, 0, 0.025),
    0px 0.997515px 2.99255px rgba(0, 0, 0, 0.0201946), 0px 0.415088px 1.24527px rgba(0, 0, 0, 0.0140573) !important;
  transform: translateY(-4px);
  min-width: 180px;
  li.retool-dropdown-menu-item {
    &:hover,
    a:hover {
      color: rgba(0, 0, 0, 0.65);
    }
    a {
      display: flex;
      align-items: center;
    }
    .retool-help-icon {
      margin-right: 6px;
    }
    &.current-version:hover {
      background: none;
      cursor: default;
    }
  }
}
