.app-protection-modal {
  .ant-modal-content {
    .ant-modal-header {
      padding: 0px;
    }
    .ant-modal-body {
      padding: 0px;
    }
  }
}
