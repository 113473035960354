.retool-collapse {
  background-color: inherit;
  .ant-collapse-item > .ant-collapse-header {
    padding: 8px 16px;
  }
  &.ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-header {
    height: 36px;
    padding-top: 0px;
  }
  .ant-collapse-item > .ant-collapse-header {
    color: var(--light-gray);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
  }
  .ant-collapse-item > .ant-collapse-header:hover > .retool-icon {
    color: var(--dark-gray) !important;
    background: var(--near-white);
    border-radius: 4px;
  }

  &.arrow-left .ant-collapse-header {
    padding-left: 20px;
  }
  &.arrow-right .ant-collapse-header {
    padding-left: 16px;
  }
  &.arrow-right .ant-collapse-header > .retool-icon {
    top: 8px;
    left: unset !important;
    right: 12px;
    position: absolute;
  }
  &.arrow-left .ant-collapse-header > .retool-icon {
    top: 13px;
    left: 0px;
    position: absolute;
  }
  &.arrow-right .ant-collapse-content-box {
    p {
      padding-right: 0px;
      padding-left: unset !important;
    }
  }
  &.arrow-left .ant-collapse-content-box {
    p {
      padding-left: 8px !important;
    }
  }
}
