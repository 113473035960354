@import 'base';

.loading-text {
  padding: 10px;
}

.loading-text__description {
  font-family: var(--sans-serif);
  color: var(--light-gray);
  text-align: center;
}
