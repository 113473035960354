/*
Application Settings Go Here
------------------------------------
This file acts as a bundler for all variables/mixins/themes, so they
can easily be swapped out without `core.scss` ever having to know.

For example:

@import './variables/colors';
@import './variables/components';
@import './themes/default';
*/

@import './antd-colors.scss';
@import './retool-colors.scss';
@import './summerfruit.scss';

$break-mobile: 640px;
$break-tablet: 840px;
$action-column-width: 110px;
$sidebar-width: 266px;

$canvas-max-width: 1560px;
$canvas-min-width: 800px;
$ideal-sans: var(--sans-serif);
$neuzeit: 'neuzeit-grotesk', sans-serif;

$widget-border-radius: 4px;

%elevation-1 {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
}

%elevation-1-emphasis {
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5), 0 2px 5px 0 rgba(0, 0, 0, 0.25);
}

%elevation-0 {
  border: 1px solid $retool-widget-outline-grey;
  box-shadow: none;
}
