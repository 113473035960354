@import 'NewAppModal';

.create-app-from-template__section-header {
  letter-spacing: 0.03em;
}

.create-app-from-template-search-icon.retool-icon {
  margin-left: -7px;
  margin-top: 0px;
}

.create-app-from-template__sidebar-container {
  flex: 0 0 auto;
}

.create-app-from-template__preview-container {
  flex: 4 1 auto;
}

.create-app-from-template__preview-screenshot-container {
  flex: 1 1 0;
  overflow: hidden;
}

.create-app-from-template__preview-nav-container {
  flex: 0 1 auto;
}
.create-app-from-template__preview-title-and-tags-container {
  flex: 1 0 auto;
}

.create-app-from-template__preview-title-description-container {
  flex: 2 1 auto;
}

.create-app-from-template__resource-container {
  min-height: 24px;
}

.create-app-from-template__preview-iframe {
  flex: 1 1 0;
  min-height: 435px;
}
