@import 'base';

.invite-form {
  border-radius: 4px;
  background-color: var(--near-white);
}

.invite-form__header {
  padding-top: 12px;
  line-height: 24px;
}

.invite-form__email-address-input {
  display: flex;

  .retool-textinput {
    width: 100%;
    margin-right: 8px;
  }

  button {
    border: none;
  }
}

.invite-form__email-address-input__close {
  display: flex;
  align-items: center;

  i {
    cursor: pointer;
    color: $retool-medium-grey;
  }
}

.bulk-invite-form__sub-container {
  justify-content: space-between;
}

.invite-form__sub-container__groups__add-button {
  display: block;
  margin-top: 20px;
}
.invite-form__sub-container__groups-action__buttons {
  display: flex;
  justify-content: flex-end;
}

.invite-modal__invite-form {
  .retool-textarea {
    .ant-input {
      font-weight: 400;
    }
  }
}
