.main {
    margin-bottom: 8px;
  }
  .description {
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 8px;
    color: var(--dark-gray);
    display: flex;
  }
  .nameRequiredAsterisk {
    color: var(--light-red);
  }
  .errorMessage {
    color: var(--light-red);
    margin-left: 8px;
  }