@import '~normalize.css/normalize';
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/addon/hint/show-hint.css';
@import '~codemirror/addon/lint/lint.css';
@import 'base';
@import '~react-table/react-table.css';
@import 'v2/style.scss';

/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2018 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Mostardesign
 * License URL: https://www.fontspring.com/licenses/mostardesign/webfont
 *
 *
 */

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: var(--dark-gray);
}

::selection {
  background: $blue-4;
}

@font-face {
  font-family: 'neuzeit-grotesk';
  src: url('./neuzeit_grotesk/neuzeit-grotesk-bold.woff2') format('woff2'),
    url('./neuzeit_grotesk/neuzeit-grotesk-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'neuzeit-grotesk';
  src: url('./neuzeit_grotesk/neuzeit-grotesk.woff2') format('woff2'),
    url('./neuzeit_grotesk/neuzeit-grotesk.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./inter/Inter-Thin-BETA.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-Thin-BETA.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('./inter/Inter-ThinItalic-BETA.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-ThinItalic-BETA.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('./inter/Inter-ExtraLight-BETA.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-ExtraLight-BETA.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('./inter/Inter-ExtraLightItalic-BETA.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-ExtraLightItalic-BETA.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./inter/Inter-Light-BETA.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-Light-BETA.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('./inter/Inter-LightItalic-BETA.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-LightItalic-BETA.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./inter/Inter-Regular.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-Regular.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('./inter/Inter-Italic.woff2?v=3.11') format('woff2'), url('./inter/Inter-Italic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./inter/Inter-Medium.woff2?v=3.11') format('woff2'), url('./inter/Inter-Medium.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('./inter/Inter-MediumItalic.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-MediumItalic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./inter/Inter-SemiBold.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-SemiBold.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('./inter/Inter-SemiBoldItalic.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-SemiBoldItalic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./inter/Inter-Bold.woff2?v=3.11') format('woff2'), url('./inter/Inter-Bold.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('./inter/Inter-BoldItalic.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-BoldItalic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./inter/Inter-ExtraBold.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-ExtraBold.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('./inter/Inter-ExtraBoldItalic.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-ExtraBoldItalic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('./inter/Inter-Black.woff2?v=3.11') format('woff2'), url('./inter/Inter-Black.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('./inter/Inter-BlackItalic.woff2?v=3.11') format('woff2'),
    url('./inter/Inter-BlackItalic.woff?v=3.11') format('woff');
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('./inter/Inter-roman.var.woff2?v=3.11') format('woff2');
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('./inter/Inter-italic.var.woff2?v=3.11') format('woff2');
}

/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url('./inter/Inter.var.woff2?v=3.11') format('woff2');
}
/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Mono'), local('RobotoMono-Regular'),
    url('./roboto_mono/roboto-mono-v7-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./roboto_mono/roboto-mono-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Mono Italic'), local('RobotoMono-Italic'),
    url('./roboto_mono/roboto-mono-v7-latin-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./roboto_mono/roboto-mono-v7-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-700 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Mono Bold'), local('RobotoMono-Bold'),
    url('./roboto_mono/roboto-mono-v7-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./roboto_mono/roboto-mono-v7-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-700italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Mono Bold Italic'), local('RobotoMono-BoldItalic'),
    url('./roboto_mono/roboto-mono-v7-latin-700italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./roboto_mono/roboto-mono-v7-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding: 0;
}

.CodeMirror pre {
  box-shadow: none;
}

.CodeMirror-gutters {
  border-right: none;
  background-color: transparent;
  margin-right: 30px;
}

.CodeMirror-linenumber {
  font-family: var(--monospace) !important;
  width: 20px;
  padding-right: 12px;
  color: #bbb;
}

.red-border {
  border-color: $retool-red;
}

.red-border:focus {
  border-color: $retool-red;
  box-shadow: 0 0 0 2px rgba(238, 95, 91, 0.2);
}

.red-border:hover {
  border-color: $retool-red;
  box-shadow: 0 0 0 2px rgba(238, 95, 91, 0.2);
}

code {
  font-family: var(--monospace) !important;
}

.CodeMirror-gutter-wrapper + .CodeMirror-line {
  //padding-left: 10px;
}

pre {
  font-family: var(--monospace) !important;
}

.CodeMirror-hints {
  z-index: 1500;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--sans-serif);
  color: var(--dark-gray);
}
h6 {
  font-family: var(--sans-serif);
  color: var(--dark-gray);
}

:focus {
  outline: none;
}

button.minimal {
  color: $grey-8;
  border: none;
  background-color: white;
  padding: 0 10px;
  margin: 0 7px;
  box-shadow: 0 0 0 1px rgba(52, 52, 95, 0.1), 0 2px 4px 0 rgba(52, 52, 95, 0.075), 0 1px 1.5px 0 rgba(0, 0, 0, 0.06),
    0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 0 0 0 transparent;
}

button.ant-btn-large.minimal > div {
  line-height: 1;
}

button.minimal.ant-btn-primary {
  background-color: transparent;
  color: $blue-7;
}
button.minimal.ant-btn-primary:hover {
  background-color: $blue-2;
  background: $blue-2;
  color: $blue-8;
}
button.minimal.ant-btn-danger {
  color: $red-7;
}
button.minimal.ant-btn-danger:hover {
  background-color: $red-2;
  background: $red-2;
  color: $red-8;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0.3em !important;
}

button.minimal:hover {
  color: $grey-8;
  background-color: $grey-4;
}

button.minimal-hover:hover,
button.minimal-hover:focus {
  color: $grey-8;
  border-color: #d9d9d9;
  background-color: $grey-4;
}
.ant-btn {
  font-weight: 500;
}
.flex-centered {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.non-ideal-state {
  height: 100%;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 12px;
  color: var(--light-gray);

  .non-ideal-state-icon {
    margin-bottom: 20px;

    i {
      font-size: 72px;
    }
  }
}

.elevation-0 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
}
.elevation-1 {
  @extend %elevation-1;
}
.elevation-2 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
}
.elevation-3 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
}
.elevation-4 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
}

.interactive {
  cursor: pointer;
  transition: 0.5s;
}
.interactive:hover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2);
}

.navbar {
  height: 50px;
  padding-left: 5px;
  padding-right: 5px;
}

.navbar-group {
  white-space: nowrap;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 50px;
}

.align-left {
  float: left;
}
.align-right {
  float: right;
}

.label-title {
  color: var(--dark-gray);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  margin-top: 12px;
  line-height: 16px;
  display: inline-block;
}

.label-title.no-top-margin {
  margin-top: 0;
}

.label-description {
  color: $grey-7;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-muted {
  color: $grey-7;
}

h1 {
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 1.2em;
  font-size: 30px;
}

h2 {
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 1.2em;
  font-size: 25px;
}

h3 {
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.8em;
  font-size: 22px;
}

h4 {
  font-weight: 500;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.9em;
  font-size: 18px;
}

h5 {
  font-weight: 500;
  font-size: var(--font-size-14);
  margin-bottom: 12px;
}

h6 {
  font-weight: 500;
  margin: 0 0 10px;
  padding: 0;
  line-height: 0.9em;
  font-size: 15px;
}

table.simple-table {
  border-collapse: collapse;
  thead {
    th {
      text-align: left;
      padding-left: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid $grey-5;
    }
  }
  tbody {
    tr td {
      padding: 10px;
      cursor: pointer;
      border: none;
    }

    tr:hover td {
      background-color: rgba(245, 229, 220, 0.8);
    }

    tr.no-hover:hover td {
      background-color: transparent;
      cursor: auto;
    }
  }
}
.empty-table-row {
  cursor: pointer;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.empty-table-row:hover {
  background-color: rgba(245, 229, 220, 0.8);
}

hr {
  margin: 8px 0;
  border: 1px solid var(--faint-gray);
  border-top: 0;
}

.shortcut-explainer {
  font-size: 10px;
  color: $grey-6;
}

.ant-collapse-content {
  overflow: visible;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.ant-select {
  width: 100%;
}

.medium-option {
  line-height: 24px !important;
}

.large-option {
  line-height: 28px !important;
}

.ant-select-lg {
  font-size: 12px !important;
}

.spr {
  font-family: var(--sans-serif);
}

.spm {
  font-family: var(--sans-serif);
}

.spsb {
  font-family: var(--sans-serif);
  font-weight: 600;
}

.ant-breadcrumb {
  font-family: var(--sans-serif);
}

.ant-form-item {
  .ant-form-item-label {
    font-family: var(--sans-serif);
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
    label {
      font-size: 14px;
    }
  }
}

.ant-form-item .ant-form-item-label {
  label {
    color: $retool-dark-grey;
  }
}
.ant-alert-description {
  line-height: var(--line-height-16);
  color: var(--gray);
}
// For action button columns (e.g. create page button)
.action-column {
  width: $action-column-width;
  margin-left: 50px;
  padding-top: 75px;

  .retool-button {
    width: $action-column-width;
    button {
      width: $action-column-width;
    }
  }
}
.retool-button.favorite-link {
  height: 20px;
}
.common-detail-view {
  .ant-form-item {
    margin-top: 32px;
    margin-bottom: 0px;
  }
}
