.keyed-table-editor {
  display: flex;
  flex-flow: wrap;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: visible;

  .key {
    flex: 0 1 25%;
    overflow: visible;
    min-width: 0;
  }
  .key-editor {
    display: flex;
    align-items: center;
  }

  .value {
    flex: 1 0 65%;
    overflow: visible;
    .cm-evaluation-wrapper {
      max-height: 32px;
    }
  }
  .value-editor {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .ReactCodeMirror {
    border-radius: 0px;
    overflow: visible;
  }
  .ReactCodeMirror .CodeMirror {
    border: none;
    border-radius: 0px;
    background: #ffffff;
    max-height: 32px;
    pre {
      padding-left: 8px;
      word-break: break-word;
    }
  }
  .ReactCodeMirror .CodeMirror.CodeMirror-focused {
    max-height: inherit;
  }
  .CodeMirror-focused {
    z-index: 99;
  }
  .key:first-of-type {
    .ReactCodeMirror {
      border-top-left-radius: 4px;
    }

    .ReactCodeMirror .CodeMirror {
      border-top-left-radius: 4px;
    }
    .ant-select-selection {
      border-top-left-radius: 4px;
    }
  }
  .value:nth-of-type(2) {
    .retool-button {
      border-top-right-radius: 4px;
    }
  }

  .kv-delete {
    width: auto;
    .retool-button {
      color: var(--lightest-gray);
      background: white;
      border-radius: 0px;
      box-shadow: var(--default-ring) !important;
      padding: 0 4px;
    }
    .retool-button:hover {
      color: var(--light-red);
    }
    z-index: 0;
  }

  .kv-delete.disabled {
    .retool-button {
      background: var(--near-white) !important;
    }
  }

  .new-row {
    padding-left: 12px;
    box-shadow: var(--default-ring) !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 100%;
    .retool-button {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .new-row.disabled {
    background: var(--near-white);
  }

  .ant-select-selection {
    border: none;
    box-shadow: var(--default-ring) !important;
    border-radius: 0px;
    min-height: 32px;
    transition: none;
  }
  .retool-select .ant-select-selection:hover,
  .retool-select .ant-select-selection:focus {
    border: none;
    box-shadow: var(--focus-ring) !important;
    z-index: 99;
  }
  .value .ant-select-selection,
  .key .ant-select-selection {
    border-radius: 0px;
    border: none;
    box-shadow: var(--default-ring) !important;
    min-height: 32px;
    transition: none;
  }
  .key:nth-last-of-type(2) .ReactCodeMirror .CodeMirror {
    border-bottom-left-radius: 4px;
  }
  .value:last-of-type .retool-button {
    border-bottom-right-radius: 4px;
  }
}

.keyed-table-editor.two-line {
  flex-direction: column;
  .key-editor {
    height: 100%;
    .cm-evaluation-wrapper {
      height: 100%;
      max-height: 64px;
    }
    .cm-evaluation-wrapper.focused-code-input {
      height: inherit;
      .ReactCodeMirror {
        height: unset;
      }
    }
    .ReactCodeMirror {
      height: 100%;
      .CodeMirror {
        height: 100%;
      }
      .CodeMirror-vscrollbar {
        overflow-y: hidden;
      }
    }
  }
  .value {
    .value-editor {
      height: 100%;
      .cm-evaluation-wrapper {
        height: 100%;
        max-height: 64px;
      }
      .cm-evaluation-wrapper.focused-code-input {
        height: inherit;
        .ReactCodeMirror {
          height: unset;
        }
      }
      .ReactCodeMirror {
        height: 100%;
        .CodeMirror {
          height: 100%;
          .CodeMirror-vscrollbar {
            overflow-y: hidden;
          }
        }
      }
      .kv-delete {
        height: 100%;
        .ant-btn {
          height: 100%;
        }
      }
    }
    .cm-evaluation-wrapper {
      max-height: 64px;
    }
  }
  .ReactCodeMirror .CodeMirror {
    max-height: 64px;
  }
}
