/*
    BASE STYLE
*/
//@import 'reset'; // Import reset only to playground
@import 'variables';
@import 'common';

#root,
body {
  font-family: var(--sans-serif);
  font-size: var(--font-size-12);
  line-height: var(--line-height-20);
  font-weight: 400;
  color: var(--dark-gray);
}

.input-label {
  font-weight: 500;
  padding-right: 8px;
  line-height: var(--line-height-14);
  display: grid;
  height: 32px;
  align-items: center;
  align-self: flex-start;
  justify-items: end;
  text-align: right;
  color: var(--dark-gray);
}
.input-label.disabled {
  color: var(--light-gray);
}
.grid-1c,
.grid-2c {
  .retool-checkbox {
    height: inherit;
  }
}

.grid-2c,
.grid-1c {
  display: grid;
  align-items: flex-start;
  grid-gap: 12px 24px;
}

.grid-2c {
  grid: auto / repeat(auto-fit, 120px minmax(280px, 1fr));
  .retool-checkbox {
    padding-top: 6px;
    // fit-content ensures that only the checkbox and its label are clickable, and not
    // the entire grid cell.
    width: fit-content;
  }
}
.grid-1c {
  grid: auto / 120px minmax(280px, 1fr);
}

.grid-offset-1 {
  grid-column-start: 2;
}
.grid-offset-1.description {
  margin-top: -8px;
}

.input-label,
.input-label-container {
  margin-right: -24px;
}
.input-label-container {
  .input-label {
    margin-right: 0px;
  }
}
.input-label.required::before {
  content: '* ';
  display: contents;
  color: var(--light-red);
}
.full-grid {
  grid-column: 1 / 3;
}
@media only screen and (max-width: 1258px) {
  .grid-1c,
  .grid-2c {
    grid: auto / auto;
    grid-gap: 12px 0px;
    .retool-checkbox {
      grid-column-end: 1;
      padding-top: 0px;
      margin-top: -8px;
    }
    .form-placeholder {
      display: none;
    }
  }
  .full-grid {
    grid-column: 1/-1;
  }
  .grid-offset-1 {
    grid-column-start: 1;
  }
  .grid-2c .retool-checkbox {
    grid-column-end: -1;
  }
  .input-label,
  .input-label-container {
    height: 16px;
    justify-self: start;
    margin-bottom: -8px;
  }
  .input-label {
    display: block;
  }
}

.ant-dropdown-menu {
  border: 1px solid var(--washed-gray);
  .ant-dropdown-menu-item {
    font-weight: 500;

    &-danger {
      color: var(--red);
    }
  }
  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item:hover {
    background: var(--near-white);
  }
}

.ant-alert-with-description {
  padding: 12px 12px 12px 44px;
  background: var(--faint-red);
  font-weight: 400;
  .ant-alert-description {
    color: var(--dark-gray);
  }
  .ant-alert-icon {
    left: 20px;
  }
}

.ant-alert-with-description .ant-icon {
  top: 12px;
  left: 20px;
}
.instruction-container {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.text-with-code code {
  font-size: 0.9em;
}
.query-main-editor-body.has-schema-sidebar {
  @media only screen and (max-width: 1560px) {
    .grid-1c,
    .grid-2c {
      grid: auto / auto;
      grid-gap: 12px 0px;
      .retool-checkbox {
        grid-column-end: 1;
      }
    }
    .input-label,
    .input-label-container {
      height: 16px;
      justify-self: start;
      margin-bottom: -8px;
    }
    .input-label {
      display: block;
    }
  }
}
