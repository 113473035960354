@import './variables.scss';

// Typography

.sans-serif {
  font-family: var(--sans-serif);
}
.code {
  font-family: var(--monospace);
}
.fs-24 {
  font-size: var(--font-size-24);
}
.fs-20 {
  font-size: var(--font-size-20);
}
.fs-18 {
  font-size: var(--font-size-18);
}
.fs-17 {
  font-size: var(--font-size-17);
}
.fs-16 {
  font-size: var(--font-size-16);
}
.fs-14 {
  font-size: var(--font-size-14);
}
.fs-13 {
  font-size: 13px;
}
.fs-12 {
  font-size: var(--font-size-12);
}
.fs-11 {
  font-size: var(--font-size-11);
}
.fs-10 {
  font-size: var(--font-size-10);
}
.lh-solid {
  line-height: 1em;
}

.lh-32 {
  line-height: var(--line-height-32);
}
.lh-28 {
  line-height: var(--line-height-28);
}
.lh-24 {
  line-height: var(--line-height-24);
}
.lh-20 {
  line-height: var(--line-height-20);
}
.lh-18 {
  line-height: 18px;
}
.lh-16 {
  line-height: var(--line-height-16);
}
.lh-14 {
  line-height: var(--line-height-14);
}
.lh-12 {
  line-height: var(--line-height-12);
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600 !important;
}

.fs-12 > code,
.code.fs-12,
.fs-12 > .code {
  font-size: 0.688rem;
}
.fs-14 > code,
.code.fs-14,
.fs-14 > .code {
  font-size: 0.813rem;
}
.heading-1 {
  font-size: var(--font-size-24);
  line-height: var(--line-height-28);
  font-weight: 600;
}
.heading-2 {
  font-size: var(--font-size-16);
  line-height: var(--line-height-20);
  font-weight: 600;
}
.section-heading {
  letter-spacing: 0.08em;
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;
}
.tt-lowercase {
  text-transform: lowercase;
}
.tt-capitalize {
  text-transform: capitalize;
}
.tc {
  text-align: center;
}
.tr {
  text-align: right;
}
// Spacing
.pa0 {
  padding: 0;
}
.pa2 {
  padding: var(--spacing-2);
}
.pa4 {
  padding: var(--spacing-4);
}
.pa8 {
  padding: var(--spacing-8);
}
.pa12 {
  padding: var(--spacing-12);
}
.pa16 {
  padding: var(--spacing-16);
}
.pa28 {
  padding: var(--spacing-28);
}
.pa32 {
  padding: var(--spacing-32);
}
.pa36 {
  padding: var(--spacing-36);
}
.pt0 {
  padding-top: 0;
}
.pt4 {
  padding-top: var(--spacing-4);
}
.pt6 {
  padding-top: var(--spacing-6);
}
.pt10 {
  padding-top: var(--spacing-10);
}
.pt20 {
  padding-top: var(--spacing-20);
}
.pt32 {
  padding-top: var(--spacing-32);
}
.pl2 {
  padding-left: var(--spacing-2);
}
.pl4 {
  padding-left: var(--spacing-4);
}
.pl6 {
  padding-left: var(--spacing-6);
}
.pl8 {
  padding-left: var(--spacing-8);
}
.pl10 {
  padding-left: var(--spacing-10);
}
.pl12 {
  padding-left: var(--spacing-12);
}
.pl16 {
  padding-left: var(--spacing-16);
}
.pl20 {
  padding-left: var(--spacing-20);
}
.pl32 {
  padding-left: var(--spacing-32);
}
.pl36 {
  padding-left: var(--spacing-36);
}
.pr4 {
  padding-right: var(--spacing-4);
}
.pr6 {
  padding-right: var(--spacing-6);
}
.pr8 {
  padding-right: var(--spacing-8);
}
.pr12 {
  padding-right: var(--spacing-12);
}
.pr16 {
  padding-right: var(--spacing-16);
}
.pr20 {
  padding-right: var(--spacing-20);
}

.pb4 {
  padding-bottom: var(--spacing-4);
}
.pb6 {
  padding-bottom: var(--spacing-6);
}
.pb8 {
  padding-bottom: var(--spacing-8);
}
.pb12 {
  padding-bottom: var(--spacing-12);
}
.pb16 {
  padding-bottom: var(--spacing-16);
}
.pb20 {
  padding-bottom: var(--spacing-20);
}
.pb24 {
  padding-bottom: var(--spacing-24);
}

.ph2 {
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
}
.ph4 {
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
}
.ph8 {
  padding-left: var(--spacing-8);
  padding-right: var(--spacing-8);
}
.ph12 {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}
.ph16 {
  padding-left: var(--spacing-16);
  padding-right: var(--spacing-16);
}
.ph20 {
  padding-left: var(--spacing-20);
  padding-right: var(--spacing-20);
}
.ph32 {
  padding-left: var(--spacing-32);
  padding-right: var(--spacing-32);
}
.ph36 {
  padding-left: var(--spacing-36);
  padding-right: var(--spacing-36);
}

.pv2 {
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
}
.pv4 {
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
}
.pv8 {
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-8);
}
.pv12 {
  padding-top: var(--spacing-12);
  padding-bottom: var(--spacing-12);
}
.pv16 {
  padding-top: var(--spacing-16);
  padding-bottom: var(--spacing-16);
}
.pv24 {
  padding-top: var(--spacing-24);
  padding-bottom: var(--spacing-24);
}
.pv32 {
  padding-top: var(--spacing-32);
  padding-bottom: var(--spacing-32);
}
.pv36 {
  padding-left: var(--spacing-36);
  padding-right: var(--spacing-36);
}
.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mv2 {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}
.ma0 {
  margin: 0;
}
.ma4 {
  margin: var(--spacing-4);
}
.mt2 {
  margin-top: var(--spacing-2);
}
.mt4 {
  margin-top: var(--spacing-4);
}
.mt8 {
  margin-top: var(--spacing-8);
}
.mt10 {
  margin-top: var(--spacing-10);
}
.mt12 {
  margin-top: var(--spacing-12);
}
.mt14 {
  margin-top: var(--spacing-14);
}
.mt16 {
  margin-top: var(--spacing-16);
}
.mt20 {
  margin-top: var(--spacing-20);
}
.mt24 {
  margin-top: var(--spacing-24);
}
.mt32 {
  margin-top: var(--spacing-32);
}
.mt36 {
  margin-top: var(--spacing-36);
}

.mb2 {
  margin-bottom: var(--spacing-2);
}
.mb4 {
  margin-bottom: var(--spacing-4);
}
.mb8 {
  margin-bottom: var(--spacing-8);
}
.mb10 {
  margin-bottom: var(--spacing-10);
}
.mb12 {
  margin-bottom: var(--spacing-12);
}
.mb16 {
  margin-bottom: var(--spacing-16);
}
.mb20 {
  margin-bottom: var(--spacing-20);
}
.mb24 {
  margin-bottom: var(--spacing-24);
}
.mb32 {
  margin-bottom: var(--spacing-12);
}
.mb36 {
  margin-bottom: var(--spacing-16);
}

.mr2 {
  margin-right: var(--spacing-2);
}
.mr4 {
  margin-right: var(--spacing-4);
}
.mr8 {
  margin-right: var(--spacing-8);
}
.mr12 {
  margin-right: var(--spacing-12);
}
.mr16 {
  margin-right: var(--spacing-16);
}
.mr20 {
  margin-right: var(--spacing-20);
}
.mr24 {
  margin-right: var(--spacing-24);
}
.mr32 {
  margin-right: var(--spacing-32);
}
.mr36 {
  margin-right: var(--spacing-36);
}

.ml4 {
  margin-left: var(--spacing-4);
}
.ml8 {
  margin-left: var(--spacing-8);
}
.ml8important {
  margin-left: var(--spacing-8) !important;
}
.ml10 {
  margin-left: var(--spacing-10);
}
.ml12 {
  margin-left: var(--spacing-12);
}
.ml13 {
  margin-left: var(--spacing-13);
}
.ml16 {
  margin-left: var(--spacing-16);
}
.ml20 {
  margin-left: var(--spacing-20);
}
.ml21 {
  margin-left: var(--spacing-21);
}
.ml24 {
  margin-left: var(--spacing-24);
}
.ml32 {
  margin-left: var(--spacing-32);
}
.ml36 {
  margin-left: var(--spacing-36);
}
// Color Skins

.white {
  color: white;
}
.dark-gray {
  color: var(--dark-gray);
}
.medium-gray {
  color: var(--medium-gray);
}
.gray {
  color: var(--gray);
}
.light-gray {
  color: var(--light-gray);
}
.lightest-gray {
  color: var(--lightest-gray);
}
.lighter-gray {
  color: var(--lighter-gray);
}
.washed-gray {
  color: var(--washed-gray);
}
.blue {
  color: var(--blue);
}
.dark-blue {
  color: var(--dark-blue);
}
.light-blue {
  color: var(--light-blue);
}
.light-red {
  color: var(--light-red);
}
.red {
  color: var(--red);
}
.dark-red {
  color: var(--dark-red);
}
.dark-green {
  color: var(--dark-green);
}
.dark-yellow {
  color: var(--dark-yellow);
}
.lightest-yellow {
  color: var(--lightest-yellow);
}
.bg-near-white {
  background: var(--near-white);
}
.bg-near-black {
  background: var(--near-black);
}
.bg-faint-gray {
  background: var(--faint-gray);
}
.bg-washed-gray {
  background: var(--washed-gray);
}
.bg-faint-red {
  background: var(--faint-red);
}

.bg-yellow {
  background: var(--yellow);
}

.bg-blue {
  background: var(--blue);
}
.bg-faint-blue {
  background: var(--faint-blue);
}
.bg-washed-blue {
  background: var(--washed-blue);
}
.bg-transparent {
  background: transparent;
}
.bg-red {
  background: var(--red);
}
.bg-faint-red {
  background: var(--faint-red);
}

.bg-green {
  background: var(--green);
}

.ba-washed-gray {
  border: 1px solid var(--washed-gray);
  box-sizing: border-box;
}
.ba-washed-gray {
  border: 1px solid var(--faint-gray);
  box-sizing: border-box;
}

.bt-washed-gray {
  box-shadow: inset 0 1px var(--washed-gray);
}
.bb-washed-gray {
  box-shadow: inset 0 -1px var(--washed-gray);
}
.bb-faint-gray {
  box-shadow: inset 0 -1px var(--faint-gray);
}
.br-washed-gray {
  border-right: 1px solid var(--washed-gray);
}
.br-faint-gray {
  border-right: 1px solid var(--faint-gray);
}
.hover-bg-light-blue:hover,
.hover-bg-light-blue:focus {
  background: var(--light-blue);
}

.hover-bg-near-white:hover,
.hover-bg-near-white:focus {
  background: var(--near-white);
}
.hover-blue:hover,
.hover-blue:focus {
  color: var(--blue);
}
.hover-bg-light-red:hover,
.hover-bg-light-red:focus {
  background: var(--light-red);
}

.hover-bg-light-green:hover,
.hover-bg-light-green:focus {
  background: var(--light-green);
}

.hover-lightest-gray:hover,
.hover-lightest-gray:focus {
  color: var(--lightest-gray);
}

.opacity-80 {
  opacity: 0.8;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fd-col {
  flex-direction: column;
}

.fd-row {
  flex-direction: row;
}

.fd-row-reverse {
  flex-direction: row-reverse;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.align-items-stretch {
  align-items: stretch;
}

.dib {
  display: inline-block;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}
.items-start {
  align-items: flex-start;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.v-mid {
  vertical-align: middle;
}
.v-bottom {
  vertical-align: bottom;
}
.v-center {
  vertical-align: center;
}
.keyboard-key,
.onboarding-modal__key {
  display: inline-flex;
  align-items: center;
  outline: 0;
  border-radius: 4px;
  border: 1px solid var(--washed-gray);
  padding: 0 2px;
  line-height: 16px;
  height: 18px;
  box-shadow: 1px 1px 0 1px var(--washed-gray);
}
.onboarding-modal__key {
  background: white;
}
.nowrap {
  white-space: nowrap;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.size40 {
  height: 40px;
  width: 40px;
}
.h-100 {
  height: 100%;
}
.h-50 {
  height: 50%;
}
.h-12p {
  height: 12px;
}
.h-24p {
  height: 24px;
}
.w-100 {
  width: 100%;
}
.w-min-0 {
  min-width: 0px;
}
.br2 {
  border-radius: 2px;
}
.br4 {
  border-radius: 4px;
}

.glowing-button,
.editorOnboardingSteps .ant-steps-item-active .ant-steps-icon-dot {
  -webkit-animation: glowing 2300ms ease-in-out infinite;
  -moz-animation: glowing 2300ms ease-in-out infinite;
  -o-animation: glowing 2300ms ease-in-out infinite;
  animation: glowing 2300ms ease-in-out infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: var(--blue);
    -webkit-box-shadow: 0 0 0 2px var(--washed-blue);
  }
  50% {
    background-color: var(--dark-blue);
    -webkit-box-shadow: 0 0 0 4px var(--washed-blue);
  }
  100% {
    background-color: var(--blue);
    -webkit-box-shadow: 0 0 0 2px var(--washed-blue);
  }
}
@keyframes glowing {
  0% {
    background-color: var(--blue);
    box-shadow: 0 0 0 2px var(--washed-blue);
  }
  50% {
    background-color: var(--dark-blue);
    box-shadow: 0 0 0 4px var(--washed-blue);
  }
  100% {
    background-color: var(--blue);
    box-shadow: 0 0 0 2px var(--washed-blue);
  }
}

.tag {
  padding: 2px 6px;
  background: rgba(74, 161, 237, 0.2);
  color: var(--blue);
  font-weight: 600;
  font-size: 10px;
  border-radius: 12px;
  line-height: 12px;
  vertical-align: bottom;
}

.beta-tag-yellow {
  padding: 2px 4px;
  background-color: var(--faint-yellow);
  color: var(--dark-yellow);
  margin-left: 4px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 12px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.transform-down-50 {
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.bb {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.b--faint-gray {
  border-color: var(--faint-gray);
}

.fuzzy-match-region {
  font-weight: 700;
  background: var(--washed-yellow);
}
