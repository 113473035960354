.home-sidebar-page-filters {
  color: var(--dark-gray);
  font-weight: 500;
  height: calc(100% - 112px);
  padding-left: 8px;
  overflow: auto;

  .global-filters {
    margin: 0;
    margin-bottom: 20px;
  }

  .page-filter {
    box-sizing: border-box;
    margin-left: -8px;
    width: 100%;
    border-radius: 4px;
    background: var(--white);
    padding: 4px 8px;
    margin-bottom: 2px;
    cursor: pointer;

    .favorite-icon.lighter-gray {
      opacity: 0;
    }
  }

  .page-filter.selected {
    background: var(--near-white);
    cursor: default;
  }

  .page-filter:hover {
    background: var(--faint-blue);

    .favorite-icon.lighter-gray {
      opacity: 1;
      transition: opacity 100ms ease-in-out;
    }
  }
}

.home-sidebar-invite {
  .retool-button.ant-btn-link {
    font-weight: 600;
  }
  .retool-button {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
