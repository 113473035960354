@import 'base';

$id: 'resource-form';

@mixin input-group {
  padding-bottom: 8px;
}

@mixin prompt {
  color: $retool-azure;
  cursor: pointer;
  font-size: 16px;
}

@mixin cancel {
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
  color: $retool-red;
}

@mixin optional-headed-group($prefix) {
  .#{$id}__#{$prefix}-prompt {
    @include prompt;
  }
  .#{$id}__#{$prefix}-label {
    font-size: 16px;
    .#{$id}__#{$prefix}-cancel {
      @include cancel;
    }
  }
}

.#{$id}__connection-string-group {
  @include input-group;
  @include optional-headed-group(connection-string);
}

.#{$id}__openapi-authentication {
  margin-top: 50px;
  margin-bottom: 50px;
  @include optional-headed-group(openapi-authentication);
  .#{$id}__openapi-authentication-inline-spinner {
    margin-left: 10px;
  }
  .#{$id}__openapi-authentication-error-message {
    color: $retool-red;
    font-size: 14px;
  }
}

.#{$id}__host-port-group,
.#{$id}__database-details-group,
.#{$id}__rest-parameters-group {
  @include input-group;
}

.#{$id}__form-body,
.#{$id}__divider-line {
  border-top: 1px $retool-grey solid;
}

.#{$id}__toggles-group {
  @include input-group;
  .#{$id}__multiline-label {
    min-height: 40px;

    .#{$id}__mini-label {
      font-size: 12px;
      white-space: normal;
    }
  }
}

.#{$id}__ssh-tunnel-group {
  @include input-group;
  padding-top: 40px;

  .#{$id}__ssh-tunnel-prompt {
    @include prompt;
  }

  .#{$id}__group-subheader {
    font-size: 16px;
    .#{$id}__ssh-tunnel-cancel {
      @include cancel;
    }
  }
}

.#{$id}__plus-button {
  font-family: sans-serif;
  display: inline-block;
  cursor: pointer;
  color: $white;
  background-color: $retool-azure;
  text-align: center;
  vertical-align: middle;
  line-height: 11px;
  margin-left: 10px;
  margin-right: -3px;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  font-size: 15px;
}

.#{$id}__plus-glyph {
  display: inline-block;
  margin-left: -1px;
}

.#{$id}__key-value-map {
  background-color: $retool-light-grey;
  padding: 30px 30px 10px 30px;
  border-radius: 9px;
  .ant-row {
    margin-bottom: 20px;
  }
}

.#{$id}__delete-button-wrapper {
  font-family: var(--sans-serif);
  color: $retool-red;
  text-align: center;
  line-height: 54px;
  font-size: 15px;
  .#{$id}__delete-button {
    cursor: pointer;
  }
}

.#{$id}__oauth-button-row {
  margin-top: 30px;
}

.#{$id}__copy-button {
  font-family: var(--sans-serif);
  font-size: 16px;
  margin-top: 8px;
}

p.ip-whitelisting-info {
  font-size: 16px;
  margin-bottom: 30px;
}

.step-editor {
  background-color: $retool-light-grey;
  padding: 0px 30px 30px 30px;
  border-radius: 9px;
  margin: 15px 0px 30px;
}

.danger-zone-header {
  color: $retool-dark-grey;
}

.#{$id}__write-popconfirm {
  max-width: 300px;

  p:last-child {
    margin: 0;
  }
}

.resource-form__key-value-map {
  .retool-dropdown {
    margin: 0;
  }
}
