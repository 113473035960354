.menuLink {
    margin-left: 4px;
    margin-right: 12px;
    font-size: var(--font-size-12);
    font-weight: 500;
    color: var(--medium-gray);
    line-height: var(--line-height-20);
  }
  .menuDropdown {
    display: flex;
    align-items: center;
  }
  .menuLinkDisabled {
    color: var(--lighter-gray);
  }