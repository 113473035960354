.move-items-modal {
  .ant-modal {
    &-header,
    &-body {
      padding: 0;
    }

    &-body {
      display: flex;
      flex-direction: column;
    }
  }

  &__option {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__subheader {
    color: var(--light-gray);
  }

  .retool-select,
  .retool-button {
    margin-top: 12px;
  }

  .retool-button {
    margin-left: auto;
  }
}
