@mixin button-reset {
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  text-align: inherit;
  user-select: auto;
}
