@import 'base';

.container {
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.core-layout__viewport {
  margin: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto; // this line was added because without it performance issues can occur (see https://github.com/tryretool/hammerhead/pull/1215)
}
