@import 'NewAppModal';

.create-folder-modal__label {
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 8px;
}

.create-folder-modal__name-required-asterisk {
  color: var(--red);
}

.create-folder-modal__submit-button {
  flex: 1 0 auto;
}
