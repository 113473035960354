@import 'base';

.name-and-folder-selector__label {
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
  color: var(--dark-gray);
}

.name-and-folder-selector__name-required-asterisk {
  color: var(--light-red);
}

.name-and-folder-selector__folder-div {
  margin-top: 20px;
}
