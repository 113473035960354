.stacked-profile-photos {
  display: flex;
  flex-direction: row-reverse;
  .profile-photo {
    border: 2px solid white;
    &:not(:last-child) {
      margin-left: -8px;
    }
  }
  .profile-photo-placeholder {
    background: var(--washed-gray);
    color: var(--light-gray);
  }
}
