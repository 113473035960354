.retool-popover {
  .ant-popover-inner {
    border: 1px solid var(--washed-gray);
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }

  padding-right: 0px;
  border-radius: 4px;

  .retool-popover-title {
    background-color: var(--faint-gray);
    height: 32px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid var(--washed-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 8px;

    .retool-popover-title__title-text {
      min-width: 0;
      max-width: 200px;
      color: var(--gray);
      font-weight: 600;
      line-height: 32px;
    }

    .retool-icon {
      color: var(--light-gray);
      &:hover {
        color: var(--dark-gray);
      }
    }
  }
}
.ant-popover-inner {
  border: 1px solid var(--washed-gray);
  margin-top: -20px;
}
.ant-popover-arrow {
  display: none;
  height: 0;
}
