.custom-logo {
  display: flex;
  justify-content: center;
  align-content: center;
  img {
    object-fit: contain;
  }
}

.login-banner {
  background-color: #fcf5e9;
  border-radius: 4px;
  color: #d69d10;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  height: 24px;
  font-size: 16px;
}
