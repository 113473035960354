@import 'base';

.retool-dropdown {
  font-family: var(--sans-serif);
  height: 36px;
  margin: 4px 0px;

  .ant-select-selection--single {
    height: 36px;
  }
  .ant-select-selection__rendered {
    line-height: 34px;
  }
  .ant-select-arrow {
    height: 10px;
  }

  &.ant-select-lg {
    font-size: 16px !important;
    height: 54px;

    .ant-select-selection {
      border-radius: 6px;
    }

    .ant-select-selection--single {
      height: 54px;
    }
    .ant-select-selection__rendered {
      line-height: 50px;
    }

    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li,
    .ant-select-selection--multiple > ul > li {
      margin-top: 5px;
      height: auto;
    }
    .ant-select-selection--multiple {
      .ant-select-selection__choice {
        background: #383838;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 10px 26px 10px 10px;
        font-size: 16px;
        line-height: 16px;
      }
      input {
        margin-left: 5px;
        margin-top: 10px;
      }
    }

    .ant-select-selection--multiple .ant-select-selection__choice__remove {
      top: 12px;
      right: 8px;
      font-size: 12px;

      &::before {
        color: #fff;
      }
    }
  }
}

.retool-dropdown-items {
  .ant-select-dropdown-menu-item {
    font-size: 12px;
    font-family: var(--sans-serif);
    line-height: 30px;
  }

  &-lg {
    .ant-select-dropdown-menu-item {
      font-size: 14px;
      font-family: var(--sans-serif);
      line-height: 50px;
    }
  }
}
.retool-dropdown--medium {
  height: 35px;
  margin: 4px 0px;

  .ant-select-selection--single {
    height: 35px;
  }
  .ant-select-selection__rendered {
    font-size: 14px;
    line-height: 33px;
  }
  .ant-select-arrow {
    height: 10px;
  }

  .ant-select-selection--multiple {
    .ant-select-selection__choice {
      height: 30px;
      border-radius: 4px;
      padding: 8px 26px 8px 8px;
      font-size: 14px;
      line-height: 14px;
    }
    input {
      margin-left: 5px;
      margin-top: 3px;
      font-size: 14px;
    }
  }
}

.retool-dropdown--small {
  height: 30px;
  margin: 4px 0px;

  .ant-select-selection--single {
    height: 30px;
  }
  .ant-select-selection__rendered {
    line-height: 28px;
  }
  .ant-select-arrow {
    height: 10px;
  }
}

.retool-icon-dropdown {
  .ant-select-selection__rendered {
    margin-left: 6px;
  }
}
