.footer {
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    width: 100%;
  }
  .label {
    margin-bottom: 16px;
    color: var(--gray);
  }