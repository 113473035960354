.feature-toggle {
  display: flex;
  div {
    display: flex;
    flex-direction: column;
  }
  b,
  span {
    font-size: 12px;
    margin-left: 15px;
  }
  button {
    margin-top: 2px;
  }
}
