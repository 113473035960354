@import 'base';

$retool-container-title-height: 40px;

.retool-canvas.mobile {
  max-width: 600px;
}
.retool-canvas-min-width.mobile {
  min-width: 240px;
}

.retool-canvas.mobile.no-mobile-layout {
  max-width: 1000px !important;
}
.retool-canvas-min-width.mobile.no-mobile-layout {
  min-width: 800px !important;
}

.retool-canvas.editor-canvas.mobile {
  max-width: 450px;
}

.retool-tabbed-container {
  display: flex;
  flex-direction: column;
  &.ant-tabs-top {
    height: 100%;
  }
  .ant-tabs-top-bar {
    min-height: 42px;
  }
  .ant-tabs-top-content {
    flex: 1 1 0;
    min-height: 0;
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-family: var(--sans-serif);
    font-weight: 600;
    font-size: 12px;
  }
  .ant-tabs-tabpane-active {
    height: 100%;
  }
  .tabpane-scrollable {
    overflow-y: var(--scrollable-overflow, auto);
    overflow-x: hidden;
  }
}

.retool-canvas-min-width {
  min-width: $canvas-min-width;
}

.retool-canvas {
  max-width: $canvas-max-width;
  height: 100%;

  &--app-shell {
    max-width: none;

    &.editor-canvas {
      background: #eee;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    font-family: var(--sans-serif);
  }
  .ant-btn[disabled],
  .ant-btn.disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    cursor: not-allowed;
  }
}

.block-component {
  @extend %elevation-1;
  border-radius: $widget-border-radius;
  background: white;
  border: 1px solid var(--washed-gray);
}

.retool-container.root {
  box-shadow: none;
  background: none !important;
  border: none;
}

.retool-container:not(.retool-modal-component) {
  border-radius: $widget-border-radius;
  height: 100%;
  background: white;

  .ant-tabs-bar {
    margin-bottom: 0;
  }
  .ant-tabs-tabpane {
    // hacky fix for the following issue: have a tabbed container with two tabs,
    // and put a table with horizontal overflow (many columns) in the second tab.
    // without this `will-change: transform`, the browser will create a layer for
    // every cell, making scrolling slow
    // repro at https://retoolin.tryretool.com/apps/abdul/tabbed%20container%20table%20layers
    will-change: transform;
    position: relative;
  }
  .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane-inactive {
    .retool-grid {
      display: none;
    }
  }
}

.retool-module-container {
  box-shadow: none;
  border-radius: $widget-border-radius;
  height: 100%;

  &.default-background-color {
    background: var(--canvas);
  }
  &.transparent-background-color {
    background: none;
  }
}

.retool-container-title-wrapper {
  height: $retool-container-title-height;
  background-color: var(--faint-gray);
  font-family: var(--sans-serif);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--gray);
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 3px 3px 0px 0px;
}

// This makes buttons elevated by default

.button-widget,
.file-picker,
.form-button-container,
.s3-uploader {
  button {
    @extend %elevation-1;
  }
}

// This makes the input part of input components elevated
.input-control-component__input {
  .ant-select-selection,
  .ant-select,
  .ant-time-picker-input,
  .ant-input {
    height: 30px;
  }
  .ant-select-selection__rendered {
    line-height: 28px;
  }
  .ant-checkbox-inner,
  .ant-radio-inner {
  }
}

.oauth-login-container__oauth-login-button,
.oauth-login-container__oauth-login-button[disabled] {
  @extend %elevation-1;
  border: 1px solid rgba(0, 0, 0, 0);
}

.retool-modal-component {
  padding-bottom: 0px;
  border-radius: $widget-border-radius;
  .block-component {
    @extend %elevation-0;
  }
  .button-widget,
  .file-picker,
  .button-group-component,
  .s3-uploader,
  .retool-form-button-container,
  .form-button-container {
    button,
    button.disabled {
      box-shadow: none;
    }
  }
  .modal-opener,
  .oauth-login-container__oauth-login-button {
    box-shadow: none;
  }
  .button-group-component button,
  .column-editor {
    @extend %elevation-0;
  }
  .input-control-component__input {
    .ant-select-selection,
    .ant-checkbox-inner,
    .ant-radio-inner,
    .ant-time-picker-input,
    .ant-input {
      @extend %elevation-0;
    }
  }
  .retool-widget {
    .ant-checkbox-inner {
      @extend %elevation-0;
    }
  }
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none !important;
  border-bottom: 1px solid $retool-widget-outline-grey !important;
}

.retool-container {
  border: 1px solid var(--washed-gray);
}

.retool-container.elevation-1.no-border {
  border: none !important;
}

.no-drop-shadow {
  box-shadow: none !important;
}

.retool-container.elevation-1.has-title {
  overflow-y: hidden !important;
}

.retool-container-grid-wrapper {
  height: 100%;
  border-radius: 4px;
}

.retool-module-container-grid-wrapper {
  --retool-grid-padding: 0;
  height: 100%;
  border-radius: 4px;
}

.retool-hidden-widget {
  outline: 2px solid pink;
}

.container-color-select-white {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 12px;
  top: 12px;
  border-radius: 50%;

  /* Retool App / White (background) */

  background: #ffffff;
  /* Retool App / Black-5 (keyline) */

  border: 1px solid #b3b3b3;
  box-sizing: border-box;
}

.retool-container-grid-wrapper.has-title {
  border-radius: 0px 0px 4px 4px;
  height: calc(100% - #{$retool-container-title-height});
}

.retool-container-grid-wrapper,
.retool-form {
  &.overflow {
    &-scroll {
      overflow-y: var(--scrollable-overflow, auto) !important;
      overflow-x: hidden;
      position: relative;
    }

    &-hidden {
      overflow: hidden;
    }
  }
}

.retool-modal-component {
  &.overflow {
    &-scroll {
      .ant-modal-body {
        overflow-y: var(--scrollable-overflow, auto) !important;
        overflow-x: hidden;
        position: relative;
      }
    }

    &-hidden {
      .ant-modal-body {
        overflow: hidden;
      }
    }
  }
}

.retool-container-title {
  overflow: hidden;
  white-space: nowrap;
  margin-right: 5px;
}

.expand-cell-button {
  color: var(--gray);
}

.listview.scrollable {
  overflow-y: var(--scrollable-overflow, auto);
  overflow-x: hidden;
  position: relative;
}

.listview-item-container {
  position: relative;
  height: 100%;
}

.listview-item {
  position: absolute;
  height: 100%;
  width: 100%;
}

.s3-uploader {
  text-align: center;
  height: 100%;
  width: 100%;

  .ant-upload {
    height: 100%;
    width: 100%;
  }
}

.retool-grid {
  --retool-grid-padding-default: 12px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: var(--retool-grid-padding, var(--retool-grid-padding-default));

  &.newRowHeight {
    --retool-grid-padding-default: 15px 12px; // 15px = 16px - 1px border
  }

  &.legacy-canvas-padding {
    --retool-grid-padding: var(--retool-grid-legacy-padding, 27.5px);
  }

  &.clip-for-drag {
    overflow: hidden;
  }

  &-content {
    flex: 1 0 100%;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 0;

    // reset padding overrides for children
    --retool-grid-padding: var(--retool-grid-padding-default);
  }

  &-placeholder {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .container-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .fetching-mask {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.4);
    pointer-events: none;
    transition-property: opacity;
    transition-duration: 0.7s;
    transition-timing-function: ease-in-out;
    opacity: 0;

    .spinner {
      font-size: 20px;
      color: $blue-7;
    }
  }
  .fetching-mask.fetching {
    pointer-events: auto;
    opacity: 1;
  }

  .retool-form {
    height: 100%;
    position: relative;
  }

  .retool-wizard {
    $bar-height: 52px;
    height: 100%;
    position: relative;

    &-grid {
      height: calc(100% - 100px);
      overflow-y: var(--scrollable-overflow, auto);
      overflow-x: hidden;
    }

    &-floating-action-bar {
      height: $bar-height;
      border-top: 1px solid var(--faint-gray);
    }

    .wizard-title {
      letter-spacing: 0.03em;
      display: flex;
      align-items: center;
      color: var(--gray);
    }
  }

  .retool-container.retool-form-container {
    @extend %elevation-1;
    border-radius: 5px;
    height: 100%;
    border: 1px solid var(--washed-gray);
  }

  .retool-container.retool-form-container.no-border {
    border: none !important;
    & > form > .retool-form-floating-action-bar {
      border: none;
    }
  }

  .retool-form-button-container {
    padding: 6px 8px;
    min-width: 120px;
    float: right;

    button {
      width: 100%;
    }
  }

  .json-explorer-container {
    padding: 0 12px;
    border-radius: 4px;
    background: rgb(255, 254, 251);
  }

  .retool-form-floating-action-bar {
    height: 40px;
    border-top: 1px solid var(--washed-gray);
  }

  .retool-form-button-container.fullwidth {
    padding: 6px 8px;
    width: 100%;
  }

  .retool-widget {
    will-change: transform;
    position: absolute;
    z-index: 0;

    &-readonly {
      padding: 5px 8px;

      &.newRowHeight {
        padding: 4px 8px;
      }
    }

    &:hover {
      z-index: 6;
    }

    &.dragging {
      pointer-events: none;
      z-index: 7;
    }

    &.selected {
      z-index: 5;
    }
  }
}

// TODO MOVE ME
.retool-modal-component-wrapper {
  pointer-events: none;
  z-index: 700;
}
.retool-modal-component-wrapper.retool-modal-component-wrapper--clickable {
  pointer-events: all;
}

.ant-modal-content {
  pointer-events: all;
}

.retool-modal-component {
  pointer-events: all;
  .ant-modal-body {
    height: 500px;
  }
}

.loading-component {
  height: 100%;
  width: 100%;
  padding: 15px;
  overflow: hidden;
  .ant-skeleton-paragraph {
    padding: 0;
  }
}

.loading-code-editor {
  width: 100%;
  height: 100%;
  .ant-skeleton-paragraph {
    width: 100%;
    padding: 0;
    li {
      width: 100% !important;
    }
  }
}
