.retool-inline-edit-wrapper {
  height: 32px;
  display: flex;
  align-items: center;
  text-align: left;

  .retool-inline-edit,
  .retool-inline-edit-input {
    font-family: var(--sans-serif);
    width: 100%;
    border-radius: 4px;
    display: block;
    height: 2em;
    line-height: 1.2em;
    font-weight: 500;
    display: inline-block;
    min-height: 28px;
    padding: 0.4rem 0.5rem;
    border: 1px solid transparent;
  }

  .retool-inline-edit-placeholder {
    color: var(--lightest-gray);
  }
  .retool-inline-edit:hover {
    background-color: var(--near-white);
  }
  .retool-inline-edit:focus,
  .retool-inline-edit-input:focus {
    outline: none;
  }
  .retool-inline-edit-input {
    border: 1px solid var(--lightest-blue);
    box-shadow: 0 0 0 3px var(--washed-blue);
    cursor: text;
  }

  position: relative;
  .retool-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: var(--light-gray);
    display: none;
    pointer-events: none;
    opacity: 0.2;
  }
}

.retool-inline-edit-wrapper:hover {
  .retool-icon {
    display: flex;
  }
}

.retool-inline-edit-wrapper-disabled {
  cursor: not-allowed;
  .retool-inline-edit:hover {
    background: inherit;
  }
}
