.ant-message {
  .ant-message-notice-content {
    font-family: var(--sans-serif);
    font-size: var(--font-size-12);
    color: var(--dark-gray);
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid var(--faint-gray);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.14);

    div {
      display: flex;
      svg {
        margin-right: var(--spacing-8);
      }
      span {
        line-height: 20px;
      }
    }
  }
}
