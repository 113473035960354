@import 'base';

$onboarding-pulse-blue: rgba(72, 220, 255, 0.4);
$onboarding-pulse-blue-trans: rgba(72, 220, 255, 0);

.pulse {
  animation: bounce 0.4s cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-direction: alternate;
  animation-iteration-count: infinite;
  opacity: 1;
  background: var(--yellow);
  color: white;
  font-size: 10px;
  top: 0;
  left: -5px;
  position: absolute !important;
  display: block;
  border-radius: 20px;
  border: 1px solid #fff;
  padding-top: 1px;
  height: 20px;
  width: 20px;
  text-align: center;
  pointer-events: none;
  opacity: 0.75;
  z-index: 999;
}

@-webkit-keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(-12px);
  }
  85% {
    transform: scale(1, 1) translateY(-3px);
  }
  100% {
    transform: scale(1.15, 0.85) translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(-12px);
  }
  85% {
    transform: scale(1, 1) translateY(-3px);
  }
  100% {
    transform: scale(1.15, 0.85) translateY(0);
  }
}
