@import 'base';

.onboarding-stage-container {
  margin-top: 28px;
  display: flex;
  border: 1px solid var(--washed-gray);
  border-radius: 4px;
  > .bg-near-white {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
@media (max-width: 1200px) {
  .onboarding-image {
    display: none;
  }
}

@media (max-width: 1080px) {
  .email-invite-container.flex {
    display: block;
  }
  .invite-content.flex {
    display: none;
  }
}
.onboarding-stage-list {
  height: 100%;
  width: 25%;
  min-width: 224px;

  padding: 20px 16px;
  padding-right: 0;

  .onboarding-stage {
    box-sizing: border-box;
    margin: 4px 0;
    padding: 4px 0;
    .label {
      flex-grow: 1;
      padding-right: 16px;
    }
  }
  .current {
    color: var(--near-black);
    border-right: 4px solid var(--light-blue);
  }
  .completed {
    text-decoration: line-through;
    color: var(--gray);
  }
  .future {
    color: var(--gray);
  }
}

.onboarding-get-help {
  color: var(--lightest-gray);

  margin: 2px;
  margin-top: 16px;
}

.close-onboarding-suggestion {
  position: absolute !important;
  top: 5px;
  right: 5px;
}

.invite-email-input {
  height: 100% !important;
}
