@import 'v2/button-reset';

.groups-header {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__actions {
    display: flex;
    flex-direction: row;
  }

  &__grid {
    margin-top: 16px;
    display: grid;
    font-weight: 500;
    letter-spacing: 0.05em;
    font-size: 11px;
    grid-template-columns: auto 15% 15% 15% 15% 100px;
    color: var(--light-gray);
    border-bottom: 1px solid var(--faint-gray);
    padding-bottom: 4px;
  }
}

.groups-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.create-new-group-modal {
  &__description {
    margin: 12px 0;
  }

  .ant-modal {
    &-header {
      padding: 0;
    }

    &-body {
      padding: 0;
    }

    &-footer {
      padding: 12px 0 0 0;
    }
  }
}

.group-row {
  display: grid;
  grid-template-columns: auto 15% 15% 15% 15% 100px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid var(--faint-gray);
  }

  &__pages,
  &__resources,
  &__users.ant-btn-link,
  &__admin.ant-btn-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--light-gray);
  }

  &__admin {
    .retool-icon {
      padding-bottom: 2px;
    }

    &.retool-button {
      justify-content: flex-start;
    }
  }

  &__name {
    @include button-reset();
    font-weight: 500;
    justify-content: flex-start;
    color: var(--near-black);
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    .retool-icon-locked {
      vertical-align: bottom;
    }
    &:hover {
      color: var(--blue);
    }
  }

  &__users {
    .stacked-profile-photos {
      margin-left: 4px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    opacity: 0;
    &--open {
      opacity: 1;
    }
  }
}
