@import 'base';

.home-container {
  height: 100%;
  font-size: 13px;
  background: white;
  max-width: 1248px;
  padding: 16px;
  padding-top: 0;
  margin: 0 auto;
  .app-browser {
    height: 100%;
    position: relative;
    .home-sidebar-page-filters {
      width: 246px;
      position: fixed;
      display: table-cell;
      margin-bottom: 64px;
      margin-top: 24px;
      height: calc(100% - 180px);
      @media screen and (max-width: $break-tablet) {
        display: none;
      }
    }

    .home-content {
      padding-left: 300px;
      width: 100%;
      height: 100%;
      @media screen and (max-width: $break-tablet) {
        padding-left: 0px;
      }
    }

    .ant-select {
      width: 100%;
    }
  }

  .loading-icon {
    color: $blue-6;
    font-size: 36px;
  }

  .page-count {
    color: var(--lightest-gray);
  }
}

.onboarding-invitation-modal .validation,
.onboarding-invitation-modal .good,
.onboarding-invitation-modal .partial,
.onboarding-invitation-modal .error {
  padding: 32px;
  font-size: var(--font-size-14);
}

@media screen and (max-width: $break-mobile) {
  .home-container .app-browser {
    padding-left: 0;
    padding-right: 0;

    .folder-view__title {
      padding-left: 25px;
    }
  }
}

.home-view-layout {
  display: flex;

  flex-direction: row;
  align-items: stretch;
}

.home-view-layout-rhs {
  display: flex;

  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}
