@import 'base';

.expiry-warning-header {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--blue);

  .retool-button {
    color: white;
    border: 1px solid white;
    border-radius: 4px;
    padding: 0 8px;
    height: 24px;
    transition: 250ms;

    &:hover {
      color: var(--blue);
      background-color: white;
    }
  }
}

.expiry-warning-header.expiry-warning-header--trial-ends-very-soon {
  background-color: var(--red);
}

.expiry-warning-header:hover {
  cursor: initial;
}
