// Normal radio button
.retool-radio.ant-radio-wrapper {
  font-size: var(--fs-12);
  font-weight: 500;
  height: 24px;
  display: flex;
  align-items: center;

  .ant-radio-inner {
    border-color: var(--lighter-gray);
    box-shadow: inset 0 0 0 0.6px var(--lighter-gray);
  }
  .ant-radio-wrapper:hover .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--light-blue);
    box-shadow: inset 0 0 0 0.6px var(--light-blue), 0 0 0 3px var(--washed-blue);
  }

  .ant-radio-checked .ant-radio-inner {
    background: var(--blue);
    border-color: var(--dark-blue);
    box-shadow: inset 0 0 0 0.6px var(--light-blue);
    &:after {
      background-color: #fff;
      top: 4px;
      left: 4px;
      width: 6px;
      height: 6px;
    }
  }
  &.ant-radio-wrapper-disabled {
    .ant-radio-wrapper:hover .ant-radio-inner,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: var(--lighter-gray);
      box-shadow: inset 0 0 0 0.6px var(--lighter-gray);
    }
    .ant-radio-inner {
      background: var(--lighter-gray);
      box-shadow: inset 0 0 0 0.6px var(--lighter-gray);
      border-color: var(--light-gray);
      &:after {
        background-color: var(--near-white);
      }
    }
  }
}

// Segmented cotrol style

.retool-radio-group.segmented-control {
  height: 24px;
  border-radius: 4px;
  font-weight: 500;
  --background-unchecked: #fff;
  --foreground-unchecked: var(--lighter-gray);
  --background-checked: var(--near-white);
  --foreground-checked: var(--dark-gray);

  --border: var(--washed-gray);

  --foreground-disabled: var(--lighter-gray);
  --background-disabled: var(--faint-gray);

  &.segmented-control--dark {
    --background-unchecked: var(--dark-gray);
    --foreground-unchecked: var(--light-gray);

    --background-checked: var(--gray);
    --foreground-checked: #fff;

    --border: var(--gray);

    --foreground-disabled: var(--gray);
    --background-disabled: var(--dark-gray);
  }
  box-shadow: 0 0 0 1px var(--border);

  &.full-width {
    display: flex;

    .retool-radio-button {
      flex: 1 1 0;
      min-width: 0;
    }
  }

  .retool-radio-button {
    border: none;
    height: 24px !important;
    line-height: 24px !important;
    background-color: var(--background-unchecked);
    color: var(--foreground-unchecked);
    padding: 0 12px;
    box-shadow: none;
    text-align: center;
  }

  .retool-radio-button:hover {
    color: var(--foreground-checked);
    box-shadow: none;
  }
  .ant-radio-button-wrapper {
    border-color: transparent;
  }

  .ant-radio-button-wrapper-checked {
    background-color: var(--background-checked) !important;
    color: var(--foreground-checked) !important;
  }
  .ant-radio-button-wrapper:not(:first-child) {
    border-left: 1px solid var(--border);
  }
  .ant-radio-button-wrapper:not(:first-child):before {
    opacity: 0;
  }
  .ant-radio-button-wrapper-checked:focus-within {
    outline: none;
  }
  .ant-radio-button-wrapper-disabled {
    background: var(--background-disabled);
    color: var(--foreground-disabled);
    &:not(:first-child) {
      border-left: 1px solid var(--border) !important;
    }
    opacity: 1;
  }
  .ant-radio-button-wrapper-disabled:hover {
    background: var(--background-disabled);
    color: var(--foreground-disabled);
  }
  .ant-radio-wrapper-disabled:hover .ant-radio-inner,
  .ant-radio-disabled:hover .ant-radio-inner {
    border-color: var(--border);
    box-shadow: inset 0 0 0 0.6px var(--border);
  }
  span > svg {
    height: 24px;
  }
}

.segmented-control.full-width.four-column {
  width: 100%;
  display: grid;
  grid: auto/1fr 1fr 1fr 1fr;
}

.segmented-control.full-width.five-column {
  width: 100%;
  display: grid;
  grid: auto/1fr 1fr 1fr 1fr 1fr;
}
