@import 'base';

.omnibox-modal {
  // TODO: Add a backdrop blur when it's finally supported.
  // See https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 810; // Omnibox modal has to appear above query editor + sidebar, which are at 800
  transition: 0.5s;
}

.collapsed-omnibox {
  font-size: 16px;
  margin-left: -26px !important;
}

.omnibox-bar {
  position: fixed;
  top: 30vh;
  width: 600px;
  left: calc(50% - 300px);
  z-index: 100;
  font-family: var(--sans-serif);

  input {
  }

  ul {
    border: none !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  }

  input {
    &::placeholder {
      opacity: 0.5;
    }
    font-family: var(--sans-serif);

    /*
      this is to override the default styling of our Input component,
      which is in a "style" attribute.
      TODO: get rid of !important when we redo default styling to be a class
    */
    border: none !important;
    border-bottom: 1px solid lightgrey !important;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    box-sizing: border-box;
    font-size: 18px;
    line-height: 18px;
    outline: 0px;
    padding: 18px 0;
    width: 100%;
  }

  .container::before {
    content: '';
  }

  .shortcutHint {
    background: #fafafa;
    padding: 12px;
    border-radius: 0 0 10px 10px;
  }

  .result-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;

    color: #383838;
  }

  .result-item-left {
    display: flex;
    align-items: center;
  }

  .result-item-icon {
    font-size: 20px;
    margin-right: 12px;
  }

  .result-item-name {
    font-size: 13px;
  }

  .result-item-type {
    display: flex;
    align-items: center;

    font-size: 13px;
    color: #a4a4a4;
  }

  .result-item--selected {
    background-color: $retool-azure;
    color: #fff;

    .result-item-type {
      color: #fff;
    }
  }

  .result-item--highlighted {
    background-color: $retool-azure;
    color: #fff;

    .result-item-type {
      color: #ddd;
    }
  }
}
