@import 'base';

.beta-tag {
  border-radius: 4px;
  background-color: var(--green);
  color: var(--white);
  padding: 2px 6px;
  margin-left: 4px;
  font-size: 12px;
  line-height: 14px;
  vertical-align: middle;
}
