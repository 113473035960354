@import 'base';

nav.top-navigation {
  color: var(--lighter-gray);
  border-bottom: 1px solid var(--washed-gray);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  padding: 0 16px;

  .logo {
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    svg {
      // NB: not `14 % 4 != 0`, however - looks better this way
      margin: 14px 0;
      color: var(--dark-gray);
    }
  }

  .customLogo {
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 8px 0;

    img {
      object-fit: contain;
    }
  }

  .main-menu {
    display: inherit;
    margin-left: 40px;
    height: 100%;
    @media screen and (max-width: $break-tablet) {
      display: none;
    }
    button {
      box-sizing: content-box;
      padding: 0 8px;
      margin: 0 8px;
      border-radius: 0;
      background: transparent;
      height: 100%;
      color: var(--medium-gray);
      border-bottom: 0px;
    }

    button:hover,
    button.active:hover {
      color: var(--blue);
      box-shadow: 0 1px 0px 0 var(--blue), inset 0 -1px 0 0 var(--blue);
    }

    button.active {
      color: var(--dark-gray);
      box-shadow: 0 1px 0px 0 var(--dark-gray), inset 0 -1px 0 0 var(--dark-gray);
    }
  }

  .left-bound {
    margin: 0;
    padding: 0;
    height: 100%;
    display: inherit;
  }

  .user-utilities {
    display: flex;
    flex-direction: row-reverse;

    .user-utility {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .large {
      padding: 12px 8px 12px 4px;
    }

    .small {
      padding: 4px;
    }
  }
}

// Dark header variation
nav.top-navigation.dark {
  background: var(--near-black);
  border-bottom: 1px solid var(--dark-gray);

  .logo svg {
    color: var(--washed-gray);
  }

  button {
    color: var(--washed-gray);
    font-weight: 500;
    border-bottom: 0px;
  }

  button:hover {
    color: var(--blue);
    box-shadow: 0 1px 0px 0 var(--blue), inset 0 -1px 0 0 var(--blue);
  }

  button.active {
    color: var(--blue);
    box-shadow: 0 1px 0px 0 var(--blue), inset 0 -1px 0 0 var(--blue);
  }
}

.top-nav-help-menu {
  width: 180px;
  margin-top: -4px;
  right: 12px;
}

.user-utility-dropdown .ant-dropdown-menu {
  margin-top: -8px;
  overflow: hidden;
  padding: 0px;
}
.onprem-user-dropdown {
  margin-top: auto;
}

.workspaces-header {
  background: var(--white);
}
