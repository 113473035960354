@import 'NewAppModal';

.pick-app-from-data-modal__label {
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 8px;
}

.pick-app-from-data-modal__datasource-required-asterisk {
  color: $retool-red;
}

.pick-app-from-data-modal__datasource-required-asterisk {
  line-height: 18px;
  font-size: 13px;
}

.pick-app-from-data-modal__resource-dropdown-image-container {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 3px;
}

.pick-app-from-data-modal__resource-dropdown-image {
  max-width: 20px;
  max-height: 15px;
  vertical-align: middle;
}

.pick-app-from-data-modal__dropdown-label {
  padding-top: 2px;
  margin-left: 3px;
}
