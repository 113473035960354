.settings-header {
  font-size: var(--font-size-24);
  margin: 32px 0 20px;
  font-weight: 600;
}

.settings-layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  background: var(--near-white);
  height: 100%;

  &__side-bar {
    background-color: var(--near-white);
    display: flex;
    flex-direction: column;
    min-width: 240px;
    height: 100%;
    width: 20%;
    overflow: auto;
  }

  &__content--container {
    flex-grow: 1;
    display: flex;
    padding: 0 32px;
    background: var(--white);
    border-left: 1px solid var(--washed-gray);
    width: 100%;
    @media only screen and (min-width: 1400px) {
      padding-left: 48px;
    }
  }

  &__content {
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }

  &__header,
  &__link {
    font-size: var(--font-size-12);
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__section {
    margin: 32px 32px 0px 32px;
  }

  &__org-name {
    color: var(--near-black);
  }

  &__header {
    color: var(--light-gray);
  }

  &__link {
    display: flex;
    flex-direction: row;
    color: var(--near-black);
    align-items: center;

    &--active {
      color: var(--light-blue);
    }

    .tag {
      margin-left: 8px;
    }
  }
}
