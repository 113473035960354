.profilePhoto {
    border-radius: 50%;
    flex-shrink: 0;
    height: var(--size);
    width: var(--size);
    &.stacked {
      border: 2px solid white;
      &:not(:last-child) {
        margin-left: -8px;
      }
    }
  }

  .profilePhotoPlaceholder {
    align-items: center;
    background: var(--medium-gray);
    color: #fff;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
  }

  .numberUsers {
    align-items: center;
    background: var(--washed-gray);
    color: #fff;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
  }