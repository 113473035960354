.body-inner {
  // background: linear-gradient(312.36deg, #334173 9.58%, #212d58 83.81%);
  background: var(--near-white);
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.error-container {
  margin-top: 10px;
}

.auth-container {
  --signup-font-size: 14px;
  width: 100%;
  padding: 80px 0;
  font-size: var(--signup-font-size);
  margin: 0 auto;
  position: relative;
  z-index: 200;
  max-width: 600px;
  .mt20 {
    margin-top: 20px;
  }
  .ant-form-item {
    font-size: var(--signup-font-size);
  }
  .retool-button,
  .retool-textinput .ant-input,
  .subdomain-form textarea,
  .subdomain-form textarea:focus {
    font-size: var(--signup-font-size);
    height: 40px;
  }
  .ant-input-group-addon {
    font-weight: 500;
    font-size: var(--signup-font-size);
  }
  .ant-select-selection__rendered {
    font-size: var(--signup-font-size);
    line-height: 40px;
  }
  .retool-select .ant-select-arrow {
    height: 40px;
  }
  .retool-button.ant-btn-primary {
    background: #6976d9;
    font-size: var(--signup-font-size);
  }
  .retool-button.ant-btn-primary:hover {
    background: #525fbe;
  }
  .auth-form-inner {
    margin-top: 20px;
  }
  .message {
    margin-top: -8px;
  }
  .ant-radio-group label {
    margin-top: 4px;
    font-size: 14px !important;
    &.ant-radio-wrapper-checked {
      font-weight: 500;
      color: var(--dark-gray);
    }
  }
  .strong {
    font-weight: 600;
  }
}

.auth-subheading {
  font-size: var(--signup-font-size);
  color: var(--light-gray);
  max-width: 420px;
}

.auth-form-container {
  border: 1px solid var(--washed-gray);
  background: white;
  padding: 48px 30px 56px;
  margin: 0 auto;
  width: 520px;
  border-radius: 6px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.03);
}

.login-form {
  &.app-form {
    margin-top: 20px;
  }
  .ant-form-item-required::before {
    display: none;
  }
}

.onprem-link,
.privacy-policy {
  margin-top: 10px;
  text-align: center;
}

// Signup theme specific (firebase, mongodb, graphql)

.form-container {
  border: 1px solid var(--washed-gray);
  background: white;
  padding: 48px 48px 56px;
  margin: 0 auto;
  width: 520px;
  border-radius: 6px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.03);
}

.logo-container {
  align-items: center;
  display: flex;
  margin-bottom: 40px;
}

.integration-logo {
  height: 38px;
}

.plus-sign {
  padding: 0 10px;
}

.subheader {
  color: var(--gray);
  font-size: var(--signup-font-size);
  max-width: 420px;
}

.auth-footer {
  color: var(--gray);
  font-size: var(--signup-font-size);
  margin-top: 10px;
  text-align: center;
}

// -- App simulation

#integration-signup-page {
  min-height: 100%;
  width: 100%;
  position: relative;
}

.integration-app {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  display: grid;
  grid-template-rows: 48px 1fr 300px;
  grid-template-columns: 1fr 280px;
  overflow: hidden;
}

.integration-app-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
}

.integration-app-nav {
  height: 48px;
  grid-row: 1;
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: row;
  background: white;
  border-bottom: 1px solid #dedede;
  .integration-app-nav-l {
    width: 240px;
    background: url('../../routes/Login/assets/integrations/app/nav-left.png') top left no-repeat;
    background-size: 100%;
  }
  .integration-app-nav-m {
    flex: 1 1 0;
    background: url('../../routes/Login/assets/integrations/app/nav-middle.png') center center no-repeat;
    background-size: 196px 47px;
  }
  .integration-app-nav-r {
    width: 240px;
    background: url('../../routes/Login/assets/integrations/app/nav-right.png') top left no-repeat;
    background-size: 100%;
  }
}

.integration-app-canvas {
  background: #f5f5f5;
  grid-row: 2 / span 2;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  .integration-app-grid {
    background: #f5f5f5;
    flex: 1 1 0;
  }
  .integration-app-queryeditor {
    height: 300px;
    width: 100%;
    background: blue;
    grid-row: 3;
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: row;
    .integration-app-queryeditor-l {
      width: 606px;
    }
    .integration-app-queryeditor-m {
      flex: 1 1 0;
    }
    .integration-app-queryeditor-r {
      width: 370px;
    }
  }
}

.integration-app-inspector {
  grid-row: 2 / span 2;
  grid-column: 2;
  background: white;
  background: url('../../routes/Login/assets/integrations/app/inspector.png') top left no-repeat;
  background-size: 100%;
  border-left: 1px solid #dedede;
}

// Pass correct query editor and canvas assets based on signup theme
// Set firebase as default if no data attribute passed
.integration-app,
.integration-app[data-theme='firebase'] {
  .integration-app-queryeditor-l {
    background: url('../../routes/Login/assets/integrations/app/qe-left-firebase.png') top left / 100% no-repeat;
  }
  .integration-app-queryeditor-m {
    background: url('../../routes/Login/assets/integrations/app/qe-middle-firebase.png') top left / 1px 300px repeat-x;
  }
  .integration-app-queryeditor-r {
    background: url('../../routes/Login/assets/integrations/app/qe-right-firebase.png') top left / 100% no-repeat;
  }
  .integration-app-canvas .integration-app-grid {
    background: url('../../routes/Login/assets/integrations/app/canvas-components-firebase.png') center center / 920px
      588px no-repeat;
  }
}
.integration-app[data-theme='graphql'] {
  .integration-app-queryeditor-l {
    background: url('../../routes/Login/assets/integrations/app/qe-left-graphql.png') top left / 100% no-repeat;
  }
  .integration-app-queryeditor-m {
    background: url('../../routes/Login/assets/integrations/app/qe-middle-graphql.png') top left / 1px 300px repeat-x;
  }
  .integration-app-queryeditor-r {
    background: url('../../routes/Login/assets/integrations/app/qe-right-graphql.png') top left / 100% no-repeat;
  }
  .integration-app-canvas .integration-app-grid {
    background: url('../../routes/Login/assets/integrations/app/canvas-components-graphql.png') center center / 1000px
      350px no-repeat;
  }
}
.integration-app[data-theme='mongodb'] {
  .integration-app-queryeditor-l {
    background: url('../../routes/Login/assets/integrations/app/qe-left-mongodb.png') top left / 100% no-repeat;
  }
  .integration-app-queryeditor-m {
    background: url('../../routes/Login/assets/integrations/app/qe-middle-mongodb.png') top left / 1px 300px repeat-x;
  }
  .integration-app-queryeditor-r {
    background: url('../../routes/Login/assets/integrations/app/qe-right-mongodb.png') top left / 100% no-repeat;
  }
  .integration-app-canvas .integration-app-grid {
    background: url('../../routes/Login/assets/integrations/app/canvas-components-mongodb.png') center center / 1210px
      641px no-repeat;
  }
}
.integration-app[data-theme='elasticsearch'] {
  .integration-app-queryeditor-l {
    background: url('../../routes/Login/assets/integrations/app/qe-left-elasticsearch.png') top left / 100% no-repeat;
  }
  .integration-app-queryeditor-m {
    background: url('../../routes/Login/assets/integrations/app/qe-middle-elasticsearch.png') top left / 1px 300px
      repeat-x;
  }
  .integration-app-queryeditor-r {
    background: url('../../routes/Login/assets/integrations/app/qe-right-mongodb.png') top left / 100% no-repeat;
  }
  .integration-app-canvas .integration-app-grid {
    background: url('../../routes/Login/assets/integrations/app/canvas-components-elasticsearch.png') center center /
      1210px 641px no-repeat;
  }
}
// -- End app simulation

@media (max-width: 1128px) {
  .testimonial-logos {
    flex-wrap: wrap;
  }
}
@media (max-width: 912px) {
  .auth-left-column {
    display: none;
  }
}

@media (max-width: 640px) {
  .auth-container {
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: none;
  }
  .auth-form-container {
    width: 100%;
    margin: 0;
    border: none;
    box-shadow: none;
    padding: 32px 28px 32px;
  }
  .form-container {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
  .body-inner {
    background: white;
  }
}
