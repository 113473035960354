@import 'base';
.retool-text-input {
  font-size: 16px;
  height: 54px;
  border-radius: 6px;
}

.retool-text-input--small {
  height: 35px;
  font-size: 14px;
}
