.content {
    margin-bottom: 12px;

    code {
      background: var(--faint-gray);
      padding: 0 4px;
      border-radius: 2px;
      font-weight: 600;
      font-size: 11px;
    }
  }