.retool-button.google-login-button,
.login-button {
  display: flex;
  justify-content: center;
  // NOTE: ResourceOAuthForm depends on this number to position its tooltip.
  // Update it if this bottom margin changes.
  margin-bottom: 8px;
  align-items: center;
  background-color: white;
  font-weight: 500;
  color: var(--near-black);
  border-color: var(--dark-gray);

  &:disabled,
  &[disabled] {
    background: var(--near-white);
    color: var(--light-gray);
    border-color: var(--washed-gray);
  }
  &:disabled:hover,
  &[disabled]:hover {
    background: var(--near-white);
    color: var(--light-gray);
    border-color: var(--washed-gray);
  }
  .logo {
    width: 14px;
    margin-right: 8px;
  }
}

.auth-button {
  height: auto;
  font-size: 1.5em;
  letter-spacing: -0.01em;
  font-family: var(--sans-serif);
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  transition: 0.2s;
}

.link-button {
  border: none;
  background: transparent;
  box-shadow: none;
  width: 100%;
  padding-top: 8px;
  font-family: var(--sans-serif);
  font-weight: 600;
  font-size: 1.3em;
  letter-spacing: -0.01em;
  color: #f0b237 !important;
}
.link-button:hover {
  color: #f0b237 !important;
}

.auth-button:hover,
.auth-button:active,
.auth-button:focus,
.auth-button:disabled,
.auth-button:disabled:hover {
  color: white;
}

.auth-container {
  max-width: 1280px;
  padding: 0 40px;
  .ant-form-item .ant-form-item-label {
    font-family: var(--sans-serif);
  }

  .ant-form-item .ant-form-item-label label {
    color: var(--dark-gray);
    font-weight: 500;
    font-size: 14px;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  h6 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: var(--dark-gray);
    font-family: var(--sans-serif);
  }

  input {
    font-family: var(--sans-serif);
    letter-spacing: 0.03em;
  }

  .ant-form-explain {
    font-family: var(--sans-serif);
    background: var(--faint-red);
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 12px;
    color: var(--red);
  }
}

.auth-form-separator {
  text-transform: uppercase;
  color: rgb(185, 185, 185);
  margin-top: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  hr {
    margin-top: 10px;
    border: none;
    border-top: 1px solid rgb(216, 216, 216);
  }
}

.auth-button-row {
  margin-top: 12px;
}

.onprem-link,
.privacy-policy {
  font-size: 13px;
  color: var(--gray);
  a {
    color: var(--blue);
  }
}

.login-app-container {
  filter: blur(1.5px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: -100;
  display: grid;
  grid-template-rows: 41px 1fr;
  height: 100vh;
  .app-navbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .app-body {
    display: grid;
    grid-template-columns: 1fr 260px;
    height: 100%;
  }
  .app-body-left {
    display: grid;
    grid-template-rows: 1fr 250px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
  .app-body-right {
    background: #f6f9fc;
    max-height: calc(100vh - 40px);
    overflow: scroll;
    padding: 20px;
  }
  .query-editor {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .widget {
    font-size: 0.9em;
    background-color: #fdfcfa;
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid #d0cfcf;
    border-radius: 8px;
    box-shadow: 5px -2px 4px 1px hsla(0, 0%, 92%, 0.5);
    margin-bottom: 10px;
  }

  .widget-name {
    font-size: 1.1em;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
    margin-bottom: 5px;
  }
  .widget-description {
    width: 100%;
    font-size: 1em;
    color: #9c9b9b;
  }
  .hamburger-menu {
    display: inline-block;
    position: relative;
    margin-left: 20px;
    margin-top: 12px;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    width: 20px;
    height: 15px;
    cursor: pointer;
  }
  .hamburger-menu::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 1px;
    background-color: #979797;
  }
  .app-name {
    display: inline-block;
    margin-left: 20px;
    color: #312929;
    font-weight: 300;
    font-size: 1.2em;
  }
  .canvas {
    color: var(--blue);
    font-size: 1.1em;
    padding: 15px;
    padding-left: 20px;
  }
  .query-editor {
    display: grid;
    overflow: auto;
    max-height: 250px;
    grid-template-rows: 40px 53px 1fr;
    .query-editor-header {
      display: grid;
      grid-template-columns: 1fr 300px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .query-tabs {
      background: #f6f9fc;
    }
    .query-tab {
      text-align: center;
      padding-top: 8px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 120px;
      background: white;
      height: 100%;
      font-family: var(--sans-serif);
      font-weight: 500;
      font-size: 1.2em;
    }
    .query-nav {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 10px;
      padding-left: 10px;
    }
    .query-resource-row {
      display: grid;
      grid-template-columns: 1fr 300px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .resource-selector {
      padding: 10px;
      padding-left: 20px;
    }
    .resource-label {
      display: inline-block;
      margin-right: 10px;
      font-weight: 600;
    }
    .resource {
      display: inline-block;
      user-select: none;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      border-top-width: 1.02px;
      padding: 6px;
      padding-left: 10px;
      width: 300px;
    }
    .resource-actions {
      padding: 10px;
      padding-right: 20px;
    }
    .action-button {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 5px;
      border: 1px solid var(--blue);
      color: var(--blue);
      display: inline-block;
      margin-left: 10px;
      float: right;
    }
    .action-button-red {
      color: red;
      border-color: red;
    }
    .resource-body {
      display: grid;
      grid-template-columns: 1fr 300px;
    }
    .parameter-label {
      font-weight: 600;
      margin-bottom: 5px;
    }
    .parameter-wrapper {
      margin-top: 15px;
    }
    .parameter {
      padding: 6px;
      padding-left: 10px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      color: gray;
      width: 100%;
    }
    .resource-body-left,
    .resource-body-right {
      padding: 20px;
    }
    .schema {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding: 20px;
      height: 200px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  h6 {
    margin-bottom: 20px;
  }
}
