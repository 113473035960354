.retool-textinput {
  position: relative;
  .ant-input {
    height: 32px;
  }
}

.retool-textinput,
.retool-textarea {
  .ant-input {
    border-radius: 4px;
    padding: var(--spacing-8);
    outline: none;
    font-weight: 500;
    border: 1px solid var(--washed-gray);
    box-sizing: border-box;
    color: var(--dark-gray);
    font-size: var(--fs-12);
    width: 100%;
    position: relative;
  }
  .ant-input:hover {
    border: 1px solid var(--washed-gray);
  }
  .ant-input.focus,
  .ant-input:focus {
    font-size: inherit;
    border: 1px solid var(--lightest-blue);
    box-shadow: 0 0 0 3px var(--washed-blue);
  }

  .code {
    font-size: 0.9em;
    font-weight: 400;
  }
  .code:focus {
    font-size: 0.9em;
  }
  .ant-input.is-error {
    border: 1px solid var(--lightest-red);
  }
  .ant-input.is-error.focus,
  .ant-input.is-error:focus {
    border: 1px solid var(--lightest-red);
    box-shadow: 0 0 0 3px var(--washed-red);
  }

  .ant-input-affix-wrapper.has-icon .ant-input,
  .ant-input.has-icon {
    padding-left: 26px; // 22px svg width + 8px padding-left + 4px padding right
  }
  .ant-input::placeholder {
    color: var(--light-gray);
  }

  .retool-icon {
    margin-top: 6px;
    margin-left: 6px;
    position: absolute;
    color: var(--lightest-gray);
    z-index: 1;
  }
  .ant-.ant-input-disabled {
    cursor: not-allowed;
    opacity: 0.85;
    background: var(--near-white);
  }
  .ant-.ant-input-affix-wrapper {
    border: none;

    .ant-input {
      padding-left: 28px;
    }
  }
}
.has-error .ant-input {
  border-color: var(--lightest-red) !important;
}
.has-error .ant-input:hover {
  box-shadow: 0 0 0 3px var(--washed-red);
}
.retool-textarea {
  .ant-input {
    resize: none;
  }
}
