.panel {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .label {
    margin-top: 16px;
  }
  .footer {
    margin-top: 16px;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    width: 100%;
  }
  .alertContainer {
    margin-top: 16px;
  }