@import 'NewAppModal';

.homepage-modal.create-app-from-data-modal.retool-modal .ant-modal-content {
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.homepage-modal.create-app-from-data-modal.retool-modal .ant-modal-body {
  padding-right: 0px;
}

.homepage-modal.create-app-from-data-modal.retool-modal .ant-modal-header {
  padding-left: 28px;
  padding-bottom: 0px;
  margin-top: -8px;
  border-bottom: 1px solid var(--washed-gray);
}

.create-app-from-data__tabs-container {
  flex: 1 0 auto;
}

.create-app-from-data-modal {
  .create-app-from-data__tabs-container,
  .ant-tabs,
  .ant-tabs-bar,
  .ant-tabs-nav-container,
  .ant-tabs-nav-wrap,
  .ant-tabs-nav-scroll,
  .ant-tabs-nav,
  .ant-tabs-tab {
    height: 48px;
  }
}
