.editor-tutorial {
  position: relative;

  width: 400px;
  padding: 20px 24px;
  border-radius: 4px;
  z-index: 900;

  background-color: var(--near-black);
  color: var(--white);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.13);
}

.editor-tutorial__spacer {
  opacity: 0.2;
  background-color: white;
  width: 64px;
  height: 2px;
  margin-right: 12px;
}
.editor-tutorial__spacer-active {
  opacity: 0.6;
}

.retool-button.editor-tutorial__prev {
  color: var(--near-white);
  &:hover {
    color: var(--white);
    opacity: 1;
  }
}

.editor-tutorial__popover {
  .ant-popover-inner {
    border: none;
    margin-top: 20px;
  }
  .ant-popover-inner-content {
    padding: 0;
    color: unset;
  }
}
