.shortcut-details {
  background: var(--near-black);
  color: var(--near-white);
  padding: 0px 12px;

  &__section-header {
    text-align: center;
    color: var(--light-gray);
    font-weight: 500;
    margin-top: 8px;
  }
  dt {
    line-height: 20px;
  }
  dl {
    margin-bottom: 12px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
    border-radius: 4px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--dark-gray);
    }
  }
}
