@import '../../../styles/v2/button-reset.scss';

.retool-tab-menu {
  display: flex;
  border-bottom: 1px solid var(--washed-gray);
  box-sizing: border-box;

  &--large {
    font-size: var(--font-size-14);
    height: 48px;
    line-height: 48px;
  }

  &--default {
    font-size: var(--font-size-12);
    height: 32px;
    line-height: 32px;
  }

  &__tab {
    @include button-reset();

    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 500;
    color: var(--light-gray);

    svg {
      height: 100%;
      line-height: inherit;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:not(:last-child) {
      margin-right: var(--spacing-16);
    }

    &:not(#{&}--active):not(:disabled):hover {
      color: var(--gray);
    }

    &--active {
      padding-top: 2px;
      color: var(--near-black);
      border-bottom: 2px solid var(--dark-gray);
    }

    &-tooltip {
      margin-left: 2px;
      color: var(--washed-gray);
      &:hover {
        color: var(--lighter-gray);
      }
    }
  }
}
