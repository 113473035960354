.reorderable-list {
  & > * + * {
    margin-top: 4px;
  }
}

.reorderable-list__item {
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: move;

  display: flex;
  align-items: center;
  font-weight: 600;

  border: 1px solid transparent;
  box-sizing: border-box;
  background-color: var(--near-white);

  &:hover {
    border: 1px solid var(--faint-gray);
  }

  .retool-icon {
    color: var(--lightest-gray);
  }
}
