.join-org-page {
  font-size: 13px;
  h3 {
    font-size: 18px;
  }
  .auth-form-container {
    width: 540px;
  }
  .ant-btn.retool-button {
    height: 32px;
    font-size: 13px;
  }
  .ant-btn.retool-button.ant-btn-primary {
    background: var(--blue);
    font-size: 13px;
    &:hover {
      background: var(--dark-blue);
    }
  }
  &__instructions {
  }
  .joinable-org-row {
    width: 100%;
    border: 1px solid var(--washed-gray);
    border-radius: 4px;
    &__content {
      display: flex;
      align-items: center;
      padding: var(--spacing-16);
    }
    &__left-side {
      flex: 3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-right: var(--spacing-16);
    }
    &__right-side {
      flex: 1;
    }
    &__basic-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .org-name {
        font-weight: 600;
        font-size: var(--font-size-14);
        max-width: 180px;
        overflow-wrap: break-word;
        line-height: 16px;
      }
      .member-invited-wrapper {
        display: flex;
        align-items: center;

        & > *:not(:first-child) {
          margin-left: 6px;
        }
      }
      .invited-tag {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        color: #e9ab11;
        background-color: #fcf5e9;
        border-radius: 4px;
        padding: 5px;
      }
      .membership-count {
        color: var(--light-gray);
      }
    }
    &__membership {
      display: flex;
      align-items: center;
    }
    &__cta {
      display: flex;
      align-items: center;
      .ant-btn {
      }
      .requested-to-join {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
