.shortcut-keys__key {
  font-size: 11px;
  margin-left: 3px;
  padding: 0px 2px;
  line-height: 16px;
  font-weight: 500;
  min-width: 18px;
  display: inline-block;
  text-align: center;
  background: var(--dark-gray);
  border: 1px solid var(--gray);
  box-sizing: border-box;
  box-shadow: 1px 1px 0px var(--gray);
  border-radius: 2px;
}
