@import 'base';

.application-listing {
  font-size: var(--font-size-13);
  margin-bottom: 64px;

  .page-actions {
    display: flex;
    align-items: center;

    .run-action {
      color: var(--blue);
      background: var(--washed-blue);
      padding-left: 12px;
      padding-right: 12px;
    }

    .run-action:hover {
      background: var(--faint-blue);
    }
  }

  div.application-listing-header {
    margin-bottom: 24px;
    margin-left: -28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div.application-listing-item {
    .retool-checkbox {
      margin-left: 4px;
    }

    .listing-item-inner {
      .listing-item-title {
        a {
          display: inline-block;
        }
      }
    }

    .ant-checkbox,
    .edit-action,
    .run-action,
    .favorite-icon {
      opacity: 0;
    }
    .composable-app-tag {
      opacity: 0;
    }
    .favorite-icon.lightest-yellow {
      opacity: 1;
    }
    .ant-checkbox-checked.ant-checkbox {
      opacity: 1;
    }
    .run-action-dropdown {
      margin: 0;
      margin-left: var(--spacing-8);
    }
    &.selected {
      background: var(--faint-blue);
      box-shadow: inset 0 -1px var(--washed-gray);
      a {
        color: var(--blue);
      }
    }
  }

  div.application-listing-item.visible {
    a {
      color: var(--blue);
    }
    .composable-app-tag,
    .ant-checkbox,
    .edit-action,
    .run-action,
    .favorite-icon {
      opacity: 1;
      transition: opacity 100ms ease-in-out;
    }
  }
}

.application-listing__protected-icon {
  margin-right: 5px;
}

.home-scroll-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.fuzzy-match-region {
  font-weight: 700;
  background: var(--washed-yellow);
}

.application-listing__menu-title {
  padding: 5px 16px;
  color: rgba(0, 0, 0, 0.45);
}
