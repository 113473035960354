.retool-alert {
  border: none;
  border-radius: 4px;
  .message-container .ant-alert-message {
    padding-left: 12px;
    font-weight: 500;
  }
  .ant-alert-icon {
    top: 12px;
  }
  &.ant-alert {
    padding: 12px 12px 12px 44px;
    font-weight: 400;
  }
  .ant-alert-message {
    line-height: 20px;
    font-size: 12px;
  }
}

.retool-alert__success {
  background-color: var(--faint-green) !important;
}
.retool-alert__info {
  background-color: var(--faint-blue) !important;
  code {
    font-weight: 700;
    &.code-block {
      background-color: var(--washed-blue);
      display: block;
      border-radius: 4px;
      padding: 8px 10px;
      font-size: 11px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}
.retool-alert__warning {
  background-color: var(--faint-yellow) !important;
}
.retool-alert__error {
  background-color: var(--faint-red) !important;
}

.ant-alert-banner {
  border-radius: 0px;
}
.retool-alert__success.ant-alert-banner {
  box-shadow: inset 0px 1px 0px var(--washed-green);
}
.retool-alert__info.ant-alert-banner {
  box-shadow: inset 0px 1px 0px #85c1f47d;
}
.retool-alert__warning.ant-alert-banner {
  box-shadow: inset 0px 1px 0px var(--washed-yellow);
}
.retool-alert__error.ant-alert-banner {
  box-shadow: inset 0px 1px 0px var(--washed-red);
}
