.container {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    z-index: 2;
    margin: 0;
    background-color: white;
    display: flex;
  }

  .noResults {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    font-size: 15px;
    color: #a4a4a4;

    .icon {
      font-size: 72px;
      margin-bottom: 20px;
    }

    .resetQueryText {
      color: #3c92dc;
      cursor: pointer;
    }
  }

  .resourceIcon {
    line-height: 0;
    max-width: 20px;
    height: 15px;
    margin-right: 8px;
  }