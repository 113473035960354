// TODO [ENG-519] note we want to keep .retool-modal-ds until
// we clean up conflicting styles in `components/standards/Modal`
.retool-modal.retool-modal-ds {
  .ant-modal-close-x {
    margin: 0;
  }
  .ant-modal-header {
    border: none;

    .ant-modal-title {
      font-family: var(--sans-serif);
      font-style: normal;
      font-weight: 600;
      font-size: var(--font-size-16);
      line-height: var(--line-height-28);
      color: var(--dark-gray);
      margin-bottom: 12px;
      margin-top: -8px;
    }
  }
  .ant-modal-content {
    border-radius: 4px;
    padding: 20px 28px 24px 28px;
    border: 1px solid var(--washed-gray);
  }
  .ant-modal-body {
    font-family: var(--sans-serif);
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-12);
    line-height: var(--line-height-16);
  }
  .ant-modal-footer {
    border: none;
    padding-top: 12px;
  }
}

.retool-modal.test-modal .ant-modal-content {
  padding: 20px 0 0 0 !important;
  padding: 0 !important;
}
